// export const ENV = 'dev'; // local => local, dev => coherent dev, poc => coherent poc, boltechDev => boltech dev env
// export const PFDomain = 'https://excelengine.sonic.coherent.global/api/v1/product/';
// export const FINANCE = 'https://finance.dev.sonic.coherent.global';
// export const NEWBUSINESS = 'https://newbusiness.dev.sonic.coherent.global/'
// export const POLICYSERVICING = 'https://policyservicing.dev.sonic.coherent.global/'
// export const CLIENT_URL = 'https://client.dev.sonic.coherent.global/'
// export const AGENT_URL = 'https://agent.dev.sonic.coherent.global/'
// export const ENQUIRY = 'https://enquiry.dev.sonic.coherent.global/'
// export const GENERAL = 'https://general.dev.sonic.coherent.global/'
// export const CLAIMS_URL = 'https://claims.dev.sonic.coherent.global/'
// export const REPORTS = 'https://reports.dev.sonic.coherent.global/'
// export const UPLOADFILE = 'https://reports.dev.sonic.coherent.global/'
// export const KEYCLOAK = 'https://keycloak.sonic.coherent.global/auth/realms/dev-sonic-global/apikey/token?apiKey=5bdce4f7-96f4-40fc-bd54-d880b5e00841&client=product-factory'
// export const POLICYENQUIRY = 'https://policy360.dev.sonic.coherent.global/enquiry/policy?policyID='
// export const IsKeyCloakTokenNeeded = true;
// export const DOMAIN = 'https://group.sonic.dev.coherent.com.hk';

export const ENV = 'devV3'; // local => local, dev => coherent dev, poc => coherent poc, boltechDev => boltech dev env
export const PFDomain = 'https://excelengine.v3.sonic.coherent.global/api/v1/product/';
export const FINANCE = 'https://finance.v3.sonic.coherent.global';
export const NEWBUSINESS = 'https://newbusiness.v3.sonic.coherent.global/'
export const POLICYSERVICING = 'https://policyservicing.v3.sonic.coherent.global/'
export const CLIENT_URL = 'https://client.v3.sonic.coherent.global/'
export const AGENT_URL = 'https://agent.v3.sonic.coherent.global/'
export const ENQUIRY = 'https://enquiry.v3.sonic.coherent.global/'
export const GENERAL = 'https://general.v3.sonic.coherent.global/'
export const CLAIMS_URL = 'https://claims.v3.sonic.coherent.global/'
export const REPORTS = 'https://reports.v3.sonic.coherent.global/'
export const UPLOADFILE = 'https://reports.v3.sonic.coherent.global/'
export const KEYCLOAK = 'https://keycloak.v3.sonic.coherent.global/auth/realms/dev-sonic-global/apikey/token?apiKey=5bdce4f7-96f4-40fc-bd54-d880b5e00841&client=product-factory'
export const POLICYENQUIRY = 'https://policy360.v3.sonic.coherent.global/enquiry/policy?policyID='
export const IsKeyCloakTokenNeeded = true;
export const DOMAIN = 'https://group.v3.sonic.coherent.com.hk';

// for Demo env
// export const PFDomain = 'https://excelengine.demo.sonic.dev.coherent.global/api/v1/product/';
// export const FINANCE = 'https://finance.demo.sonic.dev.coherent.global';
// export const NEWBUSINESS = 'https://newbusiness.demo.sonic.dev.coherent.global/'
// export const POLICYSERVICING = 'https://policyservicing.demo.sonic.dev.coherent.global/'
// export const CLIENT_URL = 'https://client.demo.sonic.dev.coherent.global/'
// export const AGENT_URL = 'https://agent.demo.sonic.dev.coherent.global/'
// export const ENQUIRY = 'https://enquiry.demo.sonic.dev.coherent.global/'
// export const GENERAL = 'https://general.demo.sonic.dev.coherent.global/'
// export const CLAIMS_URL = 'https://claims.demo.sonic.dev.coherent.global/'
// export const REPORTS = 'https://reports.demo.sonic.dev.coherent.global/'
// export const UPLOADFILE = 'https://reports.demo.sonic.dev.coherent.global/'
// export const KEYCLOAK = 'https://keycloak.demo.sonic.dev.coherent.global/auth/realms/demo-sonic-global/apikey/token?apiKey=85e01b68-1c0b-417c-ae58-2078572f111f&client=product-factory'
// export const POLICYENQUIRY = 'https://policy360.demo.sonic.dev.coherent.global/enquiry/policy?policyID='
// export const IsKeyCloakTokenNeeded = false;


// for QA env

// export const PFDomain = 'https://excelengine.sonic.coherent.global/api/v1/product/';
// export const FINANCE = 'https://finance.qa.sonic.coherent.global';
// export const NEWBUSINESS = 'https://newbusiness.qa.sonic.coherent.global/'
// export const POLICYSERVICING = 'https://policyservicing.qa.sonic.coherent.global/'
// export const CLIENT_URL = 'https://client.qa.sonic.coherent.global/'
// export const AGENT_URL = 'https://agent.qa.sonic.coherent.global/'
// export const ENQUIRY = 'https://enquiry.qa.sonic.coherent.global/'
// export const GENERAL = 'https://general.qa.sonic.coherent.global/'
// export const CLAIMS_URL = 'https://claims.qa.sonic.coherent.global/'
// export const REPORTS = 'https://reports.qa.sonic.coherent.global/'
// export const UPLOADFILE = 'https://reports.qa.sonic.coherent.global/'
// export const KEYCLOAK = 'https://keycloak.sonic.coherent.global/auth/realms/qa-sonic-global/apikey/token?apiKey=454b729d-8422-4918-8c90-67d3da214c9b&client=product-factory'
// export const POLICYENQUIRY = 'https://policy360.qa.sonic.coherent.global/enquiry/policy?policyID='
// export const IsKeyCloakTokenNeeded = true;


