const MotorPolicyData = {
    "1": {
        "AppliedPremium": null,
        "ClaimList": null,
        "CoverType": "NH_Test:Motor",
        "Coverage": "",
        "Data": null,
        "Options": null,
        "PolicyId": "",
        "RiskBody": {
            "AdditionalBenefits": null,
            "AdditionalCover": [],
            "AppliedPremium": [
                {
                    "AllocCharge": 0,
                    "AllocDate": "2022-04-20T00:00:00Z",
                    "Allocation": 2265,
                    "BuyOrder": null,
                    "CollectRef": "",
                    "Status": "",
                    "TranId": ""
                }
            ],
            "BasePremium": 2255,
            "Benefit_Limits": [
                {
                    "Benefit": "MOT1",
                    "ClaimLimit": 9999,
                    "Cover": "MOTOR",
                    "Descr": "Engine and  Gearbox Warranty",
                    "Excess": 0,
                    "PolicyLimit": 9999
                },
                {
                    "Benefit": "MOT2",
                    "ClaimLimit": 1,
                    "Cover": "MOTOR",
                    "Descr": "Repairs for your  car at any repairer",
                    "Excess": 0,
                    "PolicyLimit": 1
                },
                {
                    "Benefit": "MOT3",
                    "ClaimLimit": 1,
                    "Cover": "MOTOR",
                    "Descr": "Covered while driving in west Malaysia and parts of Thailand",
                    "Excess": 0,
                    "PolicyLimit": 1
                },
                {
                    "Benefit": "MOT4",
                    "ClaimLimit": 1,
                    "Cover": "MOTOR",
                    "Descr": "24- hour roadside assistance in case of a breakdown",
                    "Excess": 0,
                    "PolicyLimit": 1
                },
                {
                    "Benefit": "MOT8",
                    "ClaimLimit": 300,
                    "Cover": "MOTOR",
                    "Descr": "Towing costs if your car is damaged and unsafe to drive- S$300",
                    "Excess": 0,
                    "PolicyLimit": 300
                },
                {
                    "Benefit": "MOT11",
                    "ClaimLimit": 100,
                    "Cover": "MOTOR",
                    "Descr": "Windscreen repair and replacement -excess- S$100)",
                    "Excess": 0,
                    "PolicyLimit": 100
                },
                {
                    "Benefit": "MOT12",
                    "ClaimLimit": 1,
                    "Cover": "MOTOR",
                    "Descr": "12 month coverage",
                    "Excess": 0,
                    "PolicyLimit": 1
                },
                {
                    "Benefit": "MOT13",
                    "ClaimLimit": 999999,
                    "Cover": "MOTOR",
                    "Descr": "Repair or replacement if your car had been damaged due to fire or stolen",
                    "Excess": 0,
                    "PolicyLimit": 999999
                },
                {
                    "Benefit": "MOT16",
                    "ClaimLimit": 1000,
                    "Cover": "MOTOR",
                    "Descr": "Up to $1000",
                    "Excess": 0,
                    "PolicyLimit": 1000
                },
                {
                    "Benefit": "MOT17",
                    "ClaimLimit": 30000,
                    "Cover": "MOTOR",
                    "Descr": "One time payment - if driver dies or TPD in an accident - S$30,000",
                    "Excess": 0,
                    "PolicyLimit": 30000
                },
                {
                    "Benefit": "MOT18",
                    "ClaimLimit": 30000,
                    "Cover": "MOTOR",
                    "Descr": "One time payment for each passenger who Die or TPD in an accident- S$10,000 with an additional cost",
                    "Excess": 0,
                    "PolicyLimit": 30000
                },
                {
                    "Benefit": "MOT20",
                    "ClaimLimit": 5000000,
                    "Cover": "MOTOR",
                    "Descr": "Damage to third partys property - up to S$5 million",
                    "Excess": 0,
                    "PolicyLimit": 5000000
                },
                {
                    "Benefit": "MOT21",
                    "ClaimLimit": 999999,
                    "Cover": "MOTOR",
                    "Descr": "Unlimited cover if you are legally responsible for someone death or injury caused by your car ",
                    "Excess": 0,
                    "PolicyLimit": 999999
                },
                {
                    "Benefit": "MOT22",
                    "ClaimLimit": 999999,
                    "Cover": "MOTOR",
                    "Descr": "NCD, Passanger PA, Sunroof, Solar Screen",
                    "Excess": 0,
                    "PolicyLimit": 999999
                }
            ],
            "ClaimList": null,
            "Country": "Thailand",
            "CoverType": "NH_Test:Motor",
            "Data": null,
            "Excess": 0,
            "GroupPremiumDiscount": 0,
            "Gst": 0,
            "Installment_Premium": 2265,
            "InsuredParty": {
                "Dob": "2003-07-02T00:00:00Z",
                "Gender": "M",
                "GivenName": "LastName",
                "Id": {
                    "ExpiryDate": "2025-02-08",
                    "IDNumber": "4356789707958892",
                    "IDType": "National ID",
                    "IssueDate": "2020-01-20",
                    "IssuingAgency": "abcd"
                },
                "LicenseDate": "2020-01-02",
                "Name": "FirstName",
                "Occupation": "Job",
                "Title": ""
            },
            "LoadedPremium": null,
            "NamedDrivers": [
                {
                    "Dob": "2000-07-02T00:00:00Z",
                    "Gender": "M",
                    "GivenName": "LastName2",
                    "Id": {
                        "ExpiryDate": "2025-02-08",
                        "IDNumber": "4356789707958892",
                        "IDType": "National ID",
                        "IssueDate": "2020-01-20",
                        "IssuingAgency": "abcd"
                    },
                    "LicenseDate": "2021-01-02",
                    "Name": "Jack Peter",
                    "Occupation": "Job",
                    "Title": ""
                }
            ],
            "NcdYears": 0,
            "NetPremium": 2265,
            "OptionalPremium": 10,
            "Options": [],
            "PaymentTerm": 1,
            "Payment_Frequency": "1",
            "Plan": "Plan1",
            "PolicyId": "",
            "PremiumLevy": 0,
            "Promotion": "",
            "Ratings": null,
            "RiskId": "1",
            "RiskType": "MT",
            "Risk_Comm": "2022-04-20T00:00:36.940641599Z",
            "Risk_End": "2023-04-20T00:00:36.940641599Z",
            "Riskclass": "CO",
            "StampDuty": 0,
            "Status": "InForce",
            "Sum_Insured": 2343,
            "Term": 1,
            "TermUnits": "years",
            "TotalPremium": 2718,
            "UWStatus": "",
            "Vehicle": {
                "Capacity": "1800 cc",
                "ChassisNo": "02399-292ur-2-0",
                "Colour": "Gray",
                "Data": null,
                "DateOfManufacture": "2022-04-05T00:00:36.940641599Z",
                "EngineNo": "92390hf93090430",
                "EngineSize": "1000",
                "EngineType": "P",
                "Group": "",
                "Location": {
                    "Latitude": "",
                    "LocationCode": "loc",
                    "Longitude": ""
                },
                "Make": "TATA",
                "Mileage": "20 kmpl",
                "Model": "BUT01",
                "NCDYears": 0,
                "RegistrationNo": "REG34865FR5",
                "Scheme": "Weekend",
                "SeatingCapacity": "7",
                "UsageType": "Personal",
                "VehicleType": "SUV"
            }
        },
        "RiskId": "1",
        "RiskType": "MT",
        "Risk_Comm": "2022-04-20T00:00:36.940641599Z",
        "Risk_End": "2023-04-20T00:00:36.940641599Z",
        "Riskclass": "CO",
        "ContID": "Motor-22-000158"
    },
    "2": {
        "AppliedPremium": null,
        "ClaimList": null,
        "CoverType": "NH_Test:Motor",
        "Coverage": "",
        "Data": null,
        "Options": null,
        "PolicyId": "",
        "RiskBody": {
            "AdditionalBenefits": null,
            "AdditionalCover": [],
            "AppliedPremium": [
                {
                    "AllocCharge": 0,
                    "AllocDate": "2022-04-20T00:00:00Z",
                    "Allocation": 2265,
                    "BuyOrder": null,
                    "CollectRef": "",
                    "Status": "",
                    "TranId": ""
                }
            ],
            "BasePremium": 2255,
            "Benefit_Limits": [
                {
                    "Benefit": "MOT1",
                    "ClaimLimit": 9999,
                    "Cover": "MOTOR",
                    "Descr": "Engine and  Gearbox Warranty",
                    "Excess": 0,
                    "PolicyLimit": 9999
                },
                {
                    "Benefit": "MOT2",
                    "ClaimLimit": 1,
                    "Cover": "MOTOR",
                    "Descr": "Repairs for your  car at any repairer",
                    "Excess": 0,
                    "PolicyLimit": 1
                },
                {
                    "Benefit": "MOT3",
                    "ClaimLimit": 1,
                    "Cover": "MOTOR",
                    "Descr": "Covered while driving in west Malaysia and parts of Thailand",
                    "Excess": 0,
                    "PolicyLimit": 1
                },
                {
                    "Benefit": "MOT4",
                    "ClaimLimit": 1,
                    "Cover": "MOTOR",
                    "Descr": "24- hour roadside assistance in case of a breakdown",
                    "Excess": 0,
                    "PolicyLimit": 1
                },
                {
                    "Benefit": "MOT8",
                    "ClaimLimit": 300,
                    "Cover": "MOTOR",
                    "Descr": "Towing costs if your car is damaged and unsafe to drive- S$300",
                    "Excess": 0,
                    "PolicyLimit": 300
                },
                {
                    "Benefit": "MOT11",
                    "ClaimLimit": 100,
                    "Cover": "MOTOR",
                    "Descr": "Windscreen repair and replacement -excess- S$100)",
                    "Excess": 0,
                    "PolicyLimit": 100
                },
                {
                    "Benefit": "MOT12",
                    "ClaimLimit": 1,
                    "Cover": "MOTOR",
                    "Descr": "12 month coverage",
                    "Excess": 0,
                    "PolicyLimit": 1
                },
                {
                    "Benefit": "MOT13",
                    "ClaimLimit": 999999,
                    "Cover": "MOTOR",
                    "Descr": "Repair or replacement if your car had been damaged due to fire or stolen",
                    "Excess": 0,
                    "PolicyLimit": 999999
                },
                {
                    "Benefit": "MOT16",
                    "ClaimLimit": 1000,
                    "Cover": "MOTOR",
                    "Descr": "Up to $1000",
                    "Excess": 0,
                    "PolicyLimit": 1000
                },
                {
                    "Benefit": "MOT17",
                    "ClaimLimit": 30000,
                    "Cover": "MOTOR",
                    "Descr": "One time payment - if driver dies or TPD in an accident - S$30,000",
                    "Excess": 0,
                    "PolicyLimit": 30000
                },
                {
                    "Benefit": "MOT18",
                    "ClaimLimit": 30000,
                    "Cover": "MOTOR",
                    "Descr": "One time payment for each passenger who Die or TPD in an accident- S$10,000 with an additional cost",
                    "Excess": 0,
                    "PolicyLimit": 30000
                },
                {
                    "Benefit": "MOT20",
                    "ClaimLimit": 5000000,
                    "Cover": "MOTOR",
                    "Descr": "Damage to third partys property - up to S$5 million",
                    "Excess": 0,
                    "PolicyLimit": 5000000
                },
                {
                    "Benefit": "MOT21",
                    "ClaimLimit": 999999,
                    "Cover": "MOTOR",
                    "Descr": "Unlimited cover if you are legally responsible for someone death or injury caused by your car ",
                    "Excess": 0,
                    "PolicyLimit": 999999
                },
                {
                    "Benefit": "MOT22",
                    "ClaimLimit": 999999,
                    "Cover": "MOTOR",
                    "Descr": "NCD, Passanger PA, Sunroof, Solar Screen",
                    "Excess": 0,
                    "PolicyLimit": 999999
                }
            ],
            "ClaimList": null,
            "Country": "Thailand",
            "CoverType": "NH_Test:Motor",
            "Data": null,
            "Excess": 0,
            "GroupPremiumDiscount": 0,
            "Gst": 0,
            "Installment_Premium": 2265,
            "InsuredParty": {
                "Dob": "2003-07-02T00:00:00Z",
                "Gender": "M",
                "GivenName": "LastName",
                "Id": {
                    "ExpiryDate": "2025-02-08",
                    "IDNumber": "4356789707958892",
                    "IDType": "National ID",
                    "IssueDate": "2020-01-20",
                    "IssuingAgency": "abcd"
                },
                "LicenseDate": "2020-01-02",
                "Name": "FirstName",
                "Occupation": "Job",
                "Title": ""
            },
            "LoadedPremium": null,
            "NamedDrivers": [
                {
                    "Dob": "2000-07-02T00:00:00Z",
                    "Gender": "M",
                    "GivenName": "PETER",
                    "Id": {
                        "ExpiryDate": "2025-02-08",
                        "IDNumber": "4356789707958892",
                        "IDType": "National ID",
                        "IssueDate": "2020-01-20",
                        "IssuingAgency": "abcd"
                    },
                    "LicenseDate": "2021-01-02",
                    "Name": "Jack Peter",
                    "Occupation": "Job",
                    "Title": ""
                },
                {
                    "Dob": "2000-07-02T00:00:00Z",
                    "Gender": "M",
                    "GivenName": "MAX",
                    "Id": {
                        "ExpiryDate": "2025-02-08",
                        "IDNumber": "4356789707958892",
                        "IDType": "National ID",
                        "IssueDate": "2020-01-20",
                        "IssuingAgency": "abcd"
                    },
                    "LicenseDate": "2021-01-02",
                    "Name": "Max Dean",
                    "Occupation": "Job",
                    "Title": ""
                }
            ],
            "NcdYears": 0,
            "NetPremium": 2265,
            "OptionalPremium": 10,
            "Options": [],
            "PaymentTerm": 1,
            "Payment_Frequency": "1",
            "Plan": "Plan1",
            "PolicyId": "",
            "PremiumLevy": 0,
            "Promotion": "",
            "Ratings": null,
            "RiskId": "1",
            "RiskType": "MT",
            "Risk_Comm": "2022-04-20T00:00:36.940641599Z",
            "Risk_End": "2023-04-20T00:00:36.940641599Z",
            "Riskclass": "CO",
            "StampDuty": 0,
            "Status": "InForce",
            "Sum_Insured": 2343,
            "Term": 1,
            "TermUnits": "years",
            "TotalPremium": 2718,
            "UWStatus": "",
            "Vehicle": {
                "Capacity": "1800 cc",
                "ChassisNo": "02399-292ur-2-0",
                "Colour": "Gray",
                "Data": null,
                "DateOfManufacture": "2022-04-05T00:00:36.940641599Z",
                "EngineNo": "92390hf93090430",
                "EngineSize": "1000",
                "EngineType": "P",
                "Group": "",
                "Location": {
                    "Latitude": "",
                    "LocationCode": "loc",
                    "Longitude": ""
                },
                "Make": "TATA",
                "Mileage": "20 kmpl",
                "Model": "BUT01",
                "NCDYears": 0,
                "RegistrationNo": "REG34865FR5",
                "Scheme": "Weekend",
                "SeatingCapacity": "7",
                "UsageType": "Personal",
                "VehicleType": "SUV"
            }
        },
        "RiskId": "1",
        "RiskType": "MT",
        "Risk_Comm": "2022-04-20T00:00:36.940641599Z",
        "Risk_End": "2023-04-20T00:00:36.940641599Z",
        "Riskclass": "CO",
        "ContID": "Motor-22-000158"
    }
}

const HSBC_PA_PolicyData = {
    "1": {
        "RiskType": "PA",
        "PolicyId": "",
        "RiskId": "1",
        "Riskclass": "PA",
        "CoverType": "HSBC_PoC:PA_Demo",
        "Coverage": "",
        "Risk_Comm": "2023-03-22T13:23:45.871303Z",
        "Risk_End": "2023-03-22T00:00:00Z",
        "RiskBody": {
            "RiskType": "PA",
            "PolicyId": "",
            "RiskId": "1",
            "Plan": "Silver",
            "Riskclass": "PA",
            "CoverType": "HSBC_PoC:PA_Demo",
            "Coverage": "",
            "EventDate": "2022-03-22T13:23:45.871303Z",
            "InsuredParty": {
                "Title": "",
                "GivenName": "Smith",
                "Name": "John",
                "Dob": "1935-01-01",
                "Age": 28,
                "Gender": "M",
                "ClientID": "",
                "ClientType": "",
                "DataFromClient": false,
                "Id": {
                    "IDType": "National ID",
                    "IDNumber": "AA-001285123",
                    "IssueDate": "2020-01-20",
                    "ExpiryDate": "2025-02-08",
                    "IssuingAgency": "Government Agency"
                },
                "Occupation": "",
                "MortalityClass": "",
                "Kinship": "Policyholder",
                "OtherPolicyInfo": null,
                "Documents": [
                    {
                        "Url": "https://sonic-data-store.s3-ap-southeast-1.amazonaws.com/S3UplodeFolder/cycle_20210726034517726.jpg",
                        "Descr": "Cycle Photo",
                        "AttachDate": "2021-07-06T13:23:45.871303Z",
                        "AttachNote": {
                            "Dte": "2021-07-06T13:23:45.871303Z",
                            "User": "Hello",
                            "Text": "Cycle Photo"
                        }
                    }
                ],
                "Petinfo": {},
                "Contact": {
                    "Email": "arvind.kushwaha@coherent.global",
                    "Phone": "955-547-88",
                    "FbId": "",
                    "Typ": "",
                    "Add": {
                        "Add1": " ",
                        "Add2": " ",
                        "Add3": " ",
                        "Add4": "Marina Bay Sands Singapore, 10 Bayfront Ave, Singapore 018956",
                        "Add5": "",
                        "City": " ",
                        "Country": " ",
                        "Pcode": "018956"
                    },
                    "ContactType": ""
                },
                "Data": [
                    {
                        "ExtraField1": "Extra Insured Data",
                        "ExtraField2": "Extra Insured Data"
                    }
                ]
            },
            "CoInsured": null,
            "TermUnits": "years",
            "Term": 1,
            "Sum_Insured": 0,
            "EmpSalary": 0,
            "Payment_Frequency": "1",
            "AdditionalCover": null,
            "BasePremium": 574.7,
            "OptionalPremium": 0,
            "PremiumLevy": 0,
            "NetPremium": 574.7,
            "RefundPremium": 0,
            "RefundLevy": 0,
            "RefundGst": 0,
            "RefundNetPremium": 0,
            "Installment_Premium": 716.69,
            "TotalPremium": 716.69,
            "AppliedPremium": null,
            "LoadedPremium": null,
            "Risk_Comm": "2022-03-22T13:23:45.871303Z",
            "Risk_End": "2023-03-22T00:00:00Z",
            "Gst": 40.57,
            "Status": "Refer",
            "UWStatus": "",
            "Options": [],
            "Engine": "",
            "Benefit_Limits": [
                {
                    "Cover": "A0",
                    "Descr": "===== Section A cover applied to [2 Adult(s) - Policyholder & Spouse] ===== ",
                    "Benefit": "A0",
                    "ClaimLimit": 1,
                    "PolicyLimit": 1,
                    "OriginalClaimLimit": 1
                },
                {
                    "Cover": "A1",
                    "Descr": "Accidental Death (AD) / Permanent Disablement (PD) ",
                    "Benefit": "A1",
                    "ClaimLimit": 150000,
                    "PolicyLimit": 150000,
                    "OriginalClaimLimit": 150000
                },
                {
                    "Cover": "A2",
                    "Descr": "Additional 50% Payout upon AD whilst Overseas ",
                    "Benefit": "A2",
                    "ClaimLimit": 75000,
                    "PolicyLimit": 75000,
                    "OriginalClaimLimit": 75000
                },
                {
                    "Cover": "A3",
                    "Descr": "Additional 50% Payout upon AD in a Public Transport ",
                    "Benefit": "A3",
                    "ClaimLimit": 75000,
                    "PolicyLimit": 75000,
                    "OriginalClaimLimit": 75000
                },
                {
                    "Cover": "A4",
                    "Descr": "Third Degree Burns ",
                    "Benefit": "A4",
                    "ClaimLimit": 15000,
                    "PolicyLimit": 15000,
                    "OriginalClaimLimit": 15000
                },
                {
                    "Cover": "A5",
                    "Descr": "Medical Expenses (without hospitalisation, per accident) ",
                    "Benefit": "A5",
                    "ClaimLimit": 2000,
                    "PolicyLimit": 2000,
                    "OriginalClaimLimit": 2000
                },
                {
                    "Cover": "A6",
                    "Descr": "Medical Expenses (with hospitalisation, per accident) ",
                    "Benefit": "A6",
                    "ClaimLimit": 4000,
                    "PolicyLimit": 4000,
                    "OriginalClaimLimit": 4000
                },
                {
                    "Cover": "A7",
                    "Descr": "Physiotherapy / Chiropractic Treatment / TCM ",
                    "Benefit": "A7",
                    "ClaimLimit": 1000,
                    "PolicyLimit": 1000,
                    "OriginalClaimLimit": 1000
                },
                {
                    "Cover": "A9",
                    "Descr": "Mobility Aids Home and Vehicle Modification ",
                    "Benefit": "A9",
                    "ClaimLimit": 5000,
                    "PolicyLimit": 5000,
                    "OriginalClaimLimit": 5000
                },
                {
                    "Cover": "B1",
                    "Descr": "Fractures Benefit ",
                    "Benefit": "B1",
                    "ClaimLimit": 2000,
                    "PolicyLimit": 2000,
                    "OriginalClaimLimit": 2000
                },
                {
                    "Cover": "B2",
                    "Descr": "Hospital Cash Allowance Up to 365 days ",
                    "Benefit": "B2",
                    "ClaimLimit": 18250,
                    "PolicyLimit": 18250,
                    "DailyLimit": 50,
                    "OriginalClaimLimit": 18250
                },
                {
                    "Cover": "B3",
                    "Descr": "Doctor's home visit, Home nursing care, Transportation cost for follow-up treatment (Per accident)",
                    "Benefit": "B3",
                    "ClaimLimit": 1000,
                    "PolicyLimit": 1000,
                    "OriginalClaimLimit": 1000
                },
                {
                    "Cover": "B4",
                    "Descr": "Emergency Medical Evacuation/Repatriation ",
                    "Benefit": "B4",
                    "ClaimLimit": 10000,
                    "PolicyLimit": 10000,
                    "OriginalClaimLimit": 10000
                },
                {
                    "Cover": "B5",
                    "Descr": "Family Shield ",
                    "Benefit": "B5",
                    "ClaimLimit": 15000,
                    "PolicyLimit": 15000,
                    "OriginalClaimLimit": 15000
                },
                {
                    "Cover": "B6",
                    "Descr": "Funeral Expenses ",
                    "Benefit": "B6",
                    "ClaimLimit": 2000,
                    "PolicyLimit": 2000,
                    "OriginalClaimLimit": 2000
                },
                {
                    "Cover": "B7",
                    "Descr": "Loan Protector (Home, Vehicle and Education Loan) ",
                    "Benefit": "B7",
                    "ClaimLimit": 15000,
                    "PolicyLimit": 15000,
                    "OriginalClaimLimit": 15000
                },
                {
                    "Cover": "C1",
                    "Descr": "Coverage for each child - up to limit of 20% parent cover applied",
                    "Benefit": "C1",
                    "ClaimLimit": 1,
                    "PolicyLimit": 1,
                    "OriginalClaimLimit": 1
                },
                {
                    "Cover": "C2",
                    "Descr": "Children Hand, Foot and Mouth Disease",
                    "Benefit": "C2",
                    "ClaimLimit": 100,
                    "PolicyLimit": 100,
                    "OriginalClaimLimit": 100
                }
            ],
            "ClaimList": null,
            "Beneficiaries": null,
            "CommissionAmount": 0,
            "ReinsuranceTreaty": "HSBC_PoC:ReinsuranceTreaty_PA_Demo",
            "Data": [
                {
                    "ExtraField1": "Extra Risk Body Data",
                    "ExtraField2": "Extra Risk Body Data"
                },
                {
                    "ExtraField1": "Extra Risk Body Data",
                    "ExtraField2": "Extra Risk Body Data"
                }
            ],
            "GroupPremiumDiscount": 0,
            "StampDuty": 0,
            "Ratings": [
                {
                    "RatingFactor": "Coverage",
                    "Value": "Family"
                },
                {
                    "RatingFactor": "Occupation",
                    "Value": "Manual"
                },
                {
                    "RatingFactor": "Occupation_spouse",
                    "Value": "Non-Manual"
                },
                {
                    "RatingFactor": "Children_topup_cover",
                    "Value": "N"
                },
                {
                    "RatingFactor": "Add_on_pack",
                    "Value": "Y"
                },
                {
                    "RatingFactor": "Children",
                    "Value": "2"
                }
            ],
            "Channel": "Direct",
            "JointLife": "",
            "RateAdjustment": 0,
            "PremiumAdjustment": 0,
            "MemberCount": 0,
            "GroupClaim": 0
        },
        "Options": null,
        "ClaimList": null,
        "AppliedPremium": null,
        "Data": null,
        "ContID": "HSBC-PA-22-000564"
    }
}

const PA_PolicyData = {
    "1": {
        "RiskType": "PA",
        "PolicyId": "",
        "RiskId": "1",
        "Riskclass": "PA",
        "CoverType": "ThaiProtect:PAThailand_v1",
        "Coverage": "",
        "Risk_Comm": "2022-08-02T07:26:06.430929Z",
        "Risk_End": "0001-01-01T00:00:00Z",
        "RiskBody": {
            "RiskType": "PA",
            "PolicyId": "",
            "RiskId": "1",
            "Plan": "PAF02A",
            "Riskclass": "PA",
            "CoverType": "ThaiProtect:PAThailand_v1",
            "Coverage": "",
            "EventDate": "2022-08-02T07:26:06.430929Z",
            "InsuredParty": {
                "Title": "DR.",
                "GivenName": "Kushwaha",
                "Name": "Arvind",
                "Dob": "2004-08-02",
                "Age": 18,
                "Gender": "M",
                "ClientID": "",
                "ClientType": "",
                "DataFromClient": false,
                "Id": {
                    "IDType": "National ID",
                    "IDNumber": "4564556366464",
                    "IssueDate": "2020-01-20",
                    "ExpiryDate": "2025-02-08",
                    "IssuingAgency": "abcd"
                },
                "Occupation": "Doctor",
                "MortalityClass": "S",
                "Kinship": "Self",
                "OtherPolicyInfo": [
                    {
                        "PolicyID": "FWD128937",
                        "Company": "FWD",
                        "SumInsured": 1500,
                        "PolicyExpiryDate": "2022-11-06T09:40:36.940647435Z",
                        "PolicyIssuanceDate": "2022-08-02T07:26:06.430929Z",
                        "Status": "In-Force",
                        "Remark": "",
                        "PolicyAge": "1"
                    }
                ],
                "Documents": [
                    {
                        "Url": "https://sonic-data-store.s3-ap-southeast-1.amazonaws.com/S3UplodeFolder/Screen Shot 2020-02-28 at 12.18.18 PM_2022080207254782.png",
                        "Descr": "Insured Photo",
                        "AttachDate": "2022-08-02T07:26:06.430929Z",
                        "AttachNote": {
                            "Dte": "2022-08-02T07:26:06.430929Z",
                            "User": "Hello",
                            "Text": "Owner Photo"
                        }
                    },
                    {
                        "Url": "https://sonic-data-store.s3-ap-southeast-1.amazonaws.com/S3UplodeFolder/Screen Shot 2020-02-28 at 12.18.18 PM_2022080207255182.png",
                        "Descr": "ID Proof",
                        "AttachDate": "2022-08-02T07:26:06.430929Z",
                        "AttachNote": {
                            "Dte": "2022-08-02T07:26:06.430929Z",
                            "User": "Hello",
                            "Text": "ID Proff"
                        }
                    }
                ],
                "Petinfo": {},
                "Contact": {
                    "Email": "arvind.kushwaha@coherent.global",
                    "Phone": "(43)-4353-3453",
                    "FbId": "",
                    "Typ": "",
                    "Add": {
                        "Add1": "A-1408, Mount Unique",
                        "Add2": "",
                        "Add3": "Rat Burana",
                        "Add4": "Bang Pa Kok",
                        "Add5": "",
                        "City": "Bangkok",
                        "Country": "Thailand",
                        "Pcode": "10140"
                    },
                    "ContactType": ""
                },
                "Data": [
                    {
                        "PreferredLanguage": "English",
                        "Risk_Comment": "test reisk 1"
                    },
                    {
                        "ExtraField1": "Extra Insured Data",
                        "ExtraField2": "Extra Insured Data"
                    }
                ]
            },
            "CoInsured": null,
            "TermUnits": "years",
            "Term": 1,
            "Sum_Insured": 12000,
            "EmpSalary": 0,
            "Payment_Frequency": "1",
            "AdditionalCover": null,
            "BasePremium": 0,
            "OptionalPremium": 0,
            "PremiumLevy": 0,
            "NetPremium": 0,
            "RefundPremium": 0,
            "RefundLevy": 0,
            "RefundGst": 0,
            "RefundNetPremium": 0,
            "Installment_Premium": 0,
            "TotalPremium": 0,
            "AppliedPremium": null,
            "LoadedPremium": null,
            "Risk_Comm": "2022-08-02T07:26:06.430929Z",
            "Risk_End": "0001-01-01T00:00:00Z",
            "Gst": 0,
            "Status": "Ready",
            "UWStatus": "",
            "Options": null,
            "Engine": "",
            "Benefit_Limits": null,
            "ClaimList": null,
            "Beneficiaries": [
                {
                    "PersonalDets": {
                        "Name": "",
                        "GivenName": "",
                        "Dob": "2004-08-02",
                        "MaritalStatus": "Single",
                        "Gender": "M",
                        "Id": "",
                        "LegalRep": {
                            "RepresentativeId": "Legal heir of insured person",
                            "RepDets": {
                                "Name": "",
                                "GivenName": "",
                                "Dob": "08-10-1986",
                                "MaritalStatus": "Married",
                                "Gender": "M",
                                "Id": "IDNumber",
                                "Nationality": "",
                                "Occupation": "",
                                "Contact": {
                                    "Email": "",
                                    "Phone": "",
                                    "FbId": "",
                                    "Typ": "",
                                    "Add": {
                                        "Add1": "",
                                        "Add2": "",
                                        "Add3": "",
                                        "Add4": "",
                                        "Add5": "",
                                        "City": "",
                                        "Country": "",
                                        "Pcode": ""
                                    },
                                    "ContactType": ""
                                }
                            },
                            "Relationship": ""
                        },
                        "Nationality": "Singaporean",
                        "Occupation": "HomeMaker",
                        "Contact": {
                            "Email": "sample@sample.com",
                            "Phone": "",
                            "FbId": "",
                            "Typ": "",
                            "Add": {
                                "Add1": "1 Thailand St.",
                                "Add2": "Street 2 of Bangkok",
                                "Add3": "District 3",
                                "Add4": "",
                                "Add5": "",
                                "City": "Bangkok",
                                "Country": "Thailand",
                                "Pcode": "1016"
                            },
                            "ContactType": ""
                        }
                    },
                    "BenefitAmt": 1000.65,
                    "Payee": {
                        "AccountName": "AcountName",
                        "Bank": "BankName",
                        "Branch": "BankBranch",
                        "Account": "AccountNo",
                        "IBAN": "IBAN",
                        "Address": {
                            "Add1": "1 Thailand St.",
                            "Add2": "Street 2 of Bangkok",
                            "Add3": "District 3",
                            "Add4": "",
                            "Add5": "",
                            "City": "Bangkok",
                            "Country": "Thailand",
                            "Pcode": "1016"
                        }
                    }
                }
            ],
            "CommissionAmount": 0,
            "ReinsuranceTreaty": "",
            "Data": [
                {
                    "PreferredLanguage": "English"
                },
                {
                    "ExtraField1": "Extra Risk Body Data",
                    "ExtraField2": "Extra Risk Body Data"
                }
            ],
            "GroupPremiumDiscount": 0,
            "StampDuty": 0,
            "Ratings": null,
            "Channel": "PRU",
            "JointLife": "",
            "RateAdjustment": 0,
            "PremiumAdjustment": 0,
            "MemberCount": 0,
            "GroupClaim": 0
        },
        "Options": null,
        "ClaimList": null,
        "AppliedPremium": null,
        "Data": null
    },
    "2": {
        "RiskType": "PA",
        "PolicyId": "",
        "RiskId": "2",
        "Riskclass": "PA",
        "CoverType": "ThaiProtect:PAThailand_v1",
        "Coverage": "",
        "Risk_Comm": "2022-08-02T07:26:06.43097Z",
        "Risk_End": "0001-01-01T00:00:00Z",
        "RiskBody": {
            "RiskType": "PA",
            "PolicyId": "",
            "RiskId": "2",
            "Plan": "PAF02A",
            "Riskclass": "PA",
            "CoverType": "ThaiProtect:PAThailand_v1",
            "Coverage": "",
            "EventDate": "2022-08-02T07:26:06.43097Z",
            "InsuredParty": {
                "Title": "MR.",
                "GivenName": "K",
                "Name": "Arvind Insured",
                "Dob": "2004-08-01",
                "Age": 18,
                "Gender": "M",
                "ClientID": "",
                "ClientType": "",
                "DataFromClient": false,
                "Id": {
                    "IDType": "National ID",
                    "IDNumber": "2442442342323",
                    "IssueDate": "2020-01-20",
                    "ExpiryDate": "2025-02-08",
                    "IssuingAgency": "abcd"
                },
                "Occupation": "Doctor",
                "MortalityClass": "S",
                "Kinship": "Spouse",
                "OtherPolicyInfo": [
                    {
                        "PolicyID": "FWD128937",
                        "Company": "FWD",
                        "SumInsured": 1500,
                        "PolicyExpiryDate": "2022-11-06T09:40:36.940647435Z",
                        "PolicyIssuanceDate": "2022-08-02T07:26:06.43097Z",
                        "Status": "In-Force",
                        "Remark": "",
                        "PolicyAge": "1"
                    }
                ],
                "Documents": [],
                "Petinfo": {},
                "Contact": {
                    "Email": "arvind.kushwaha@coherent.global",
                    "Phone": "(23)-4344-2343",
                    "FbId": "",
                    "Typ": "",
                    "Add": {
                        "Add1": "A-1408, Mount Unique",
                        "Add2": "",
                        "Add3": "Rat Burana",
                        "Add4": "Bang Pa Kok",
                        "Add5": "",
                        "City": "Bangkok",
                        "Country": "Thailand",
                        "Pcode": "10140"
                    },
                    "ContactType": ""
                },
                "Data": [
                    {
                        "PreferredLanguage": "English",
                        "Risk_Comment": ""
                    },
                    {
                        "ExtraField1": "Extra Insured Data",
                        "ExtraField2": "Extra Insured Data"
                    }
                ]
            },
            "CoInsured": null,
            "TermUnits": "years",
            "Term": 1,
            "Sum_Insured": 12000,
            "EmpSalary": 0,
            "Payment_Frequency": "1",
            "AdditionalCover": null,
            "BasePremium": 0,
            "OptionalPremium": 0,
            "PremiumLevy": 0,
            "NetPremium": 0,
            "RefundPremium": 0,
            "RefundLevy": 0,
            "RefundGst": 0,
            "RefundNetPremium": 0,
            "Installment_Premium": 0,
            "TotalPremium": 0,
            "AppliedPremium": null,
            "LoadedPremium": null,
            "Risk_Comm": "2022-08-02T07:26:06.43097Z",
            "Risk_End": "0001-01-01T00:00:00Z",
            "Gst": 0,
            "Status": "Ready",
            "UWStatus": "",
            "Options": null,
            "Engine": "",
            "Benefit_Limits": null,
            "ClaimList": null,
            "Beneficiaries": [
                {
                    "PersonalDets": {
                        "Name": "",
                        "GivenName": "",
                        "Dob": "2004-08-01",
                        "MaritalStatus": "Single",
                        "Gender": "M",
                        "Id": "",
                        "LegalRep": {
                            "RepresentativeId": "Legal heir of insured person",
                            "RepDets": {
                                "Name": "",
                                "GivenName": "",
                                "Dob": "08-10-1986",
                                "MaritalStatus": "Married",
                                "Gender": "M",
                                "Id": "IDNumber",
                                "Nationality": "",
                                "Occupation": "",
                                "Contact": {
                                    "Email": "",
                                    "Phone": "",
                                    "FbId": "",
                                    "Typ": "",
                                    "Add": {
                                        "Add1": "",
                                        "Add2": "",
                                        "Add3": "",
                                        "Add4": "",
                                        "Add5": "",
                                        "City": "",
                                        "Country": "",
                                        "Pcode": ""
                                    },
                                    "ContactType": ""
                                }
                            },
                            "Relationship": ""
                        },
                        "Nationality": "Singaporean",
                        "Occupation": "HomeMaker",
                        "Contact": {
                            "Email": "sample@sample.com",
                            "Phone": "",
                            "FbId": "",
                            "Typ": "",
                            "Add": {
                                "Add1": "1 Thailand St.",
                                "Add2": "Street 2 of Bangkok",
                                "Add3": "District 3",
                                "Add4": "",
                                "Add5": "",
                                "City": "Bangkok",
                                "Country": "Thailand",
                                "Pcode": "1016"
                            },
                            "ContactType": ""
                        }
                    },
                    "BenefitAmt": 1000.65,
                    "Payee": {
                        "AccountName": "AcountName",
                        "Bank": "BankName",
                        "Branch": "BankBranch",
                        "Account": "AccountNo",
                        "IBAN": "IBAN",
                        "Address": {
                            "Add1": "1 Thailand St.",
                            "Add2": "Street 2 of Bangkok",
                            "Add3": "District 3",
                            "Add4": "",
                            "Add5": "",
                            "City": "Bangkok",
                            "Country": "Thailand",
                            "Pcode": "1016"
                        }
                    }
                }
            ],
            "CommissionAmount": 0,
            "ReinsuranceTreaty": "",
            "Data": [
                {
                    "PreferredLanguage": "English"
                },
                {
                    "ExtraField1": "Extra Risk Body Data",
                    "ExtraField2": "Extra Risk Body Data"
                }
            ],
            "GroupPremiumDiscount": 0,
            "StampDuty": 0,
            "Ratings": null,
            "Channel": "PRU",
            "JointLife": "",
            "RateAdjustment": 0,
            "PremiumAdjustment": 0,
            "MemberCount": 0,
            "GroupClaim": 0
        },
        "Options": null,
        "ClaimList": null,
        "AppliedPremium": null,
        "Data": null
    },
    "3": {
        "RiskType": "PA",
        "PolicyId": "",
        "RiskId": "3",
        "Riskclass": "PA",
        "CoverType": "ThaiProtect:PAThailand_v1",
        "Coverage": "",
        "Risk_Comm": "2022-08-02T07:26:06.430993Z",
        "Risk_End": "0001-01-01T00:00:00Z",
        "RiskBody": {
            "RiskType": "PA",
            "PolicyId": "",
            "RiskId": "3",
            "Plan": "PAFA-C",
            "Riskclass": "PA",
            "CoverType": "ThaiProtect:PAThailand_v1",
            "Coverage": "",
            "EventDate": "2022-08-02T07:26:06.430993Z",
            "InsuredParty": {
                "Title": "MR.",
                "GivenName": "K",
                "Name": "Arvind Insured 2",
                "Dob": "2021-08-02",
                "Age": 1,
                "Gender": "M",
                "ClientID": "",
                "ClientType": "",
                "DataFromClient": false,
                "Id": {
                    "IDType": "National ID",
                    "IDNumber": "3467655867666",
                    "IssueDate": "2020-01-20",
                    "ExpiryDate": "2025-02-08",
                    "IssuingAgency": "abcd"
                },
                "Occupation": "Student",
                "MortalityClass": "S",
                "Kinship": "Child",
                "OtherPolicyInfo": [
                    {
                        "PolicyID": "FWD128937",
                        "Company": "FWD",
                        "SumInsured": 1500,
                        "PolicyExpiryDate": "2022-11-06T09:40:36.940647435Z",
                        "PolicyIssuanceDate": "2022-08-02T07:26:06.430993Z",
                        "Status": "In-Force",
                        "Remark": "",
                        "PolicyAge": "1"
                    }
                ],
                "Documents": [],
                "Petinfo": {},
                "Contact": {
                    "Email": "arvind.kushwaha@coherent.global",
                    "Phone": "(43)-4345-4353",
                    "FbId": "",
                    "Typ": "",
                    "Add": {
                        "Add1": "A-1408, Mount Unique",
                        "Add2": "",
                        "Add3": "Rat Burana",
                        "Add4": "Bang Pa Kok",
                        "Add5": "",
                        "City": "Bangkok",
                        "Country": "Thailand",
                        "Pcode": "10140"
                    },
                    "ContactType": ""
                },
                "Data": [
                    {
                        "PreferredLanguage": "English",
                        "Risk_Comment": "Risk 3"
                    },
                    {
                        "ExtraField1": "Extra Insured Data",
                        "ExtraField2": "Extra Insured Data"
                    }
                ]
            },
            "CoInsured": null,
            "TermUnits": "years",
            "Term": 1,
            "Sum_Insured": 12000,
            "EmpSalary": 0,
            "Payment_Frequency": "1",
            "AdditionalCover": null,
            "BasePremium": 0,
            "OptionalPremium": 0,
            "PremiumLevy": 0,
            "NetPremium": 0,
            "RefundPremium": 0,
            "RefundLevy": 0,
            "RefundGst": 0,
            "RefundNetPremium": 0,
            "Installment_Premium": 0,
            "TotalPremium": 0,
            "AppliedPremium": null,
            "LoadedPremium": null,
            "Risk_Comm": "2022-08-02T07:26:06.430993Z",
            "Risk_End": "0001-01-01T00:00:00Z",
            "Gst": 0,
            "Status": "Ready",
            "UWStatus": "",
            "Options": null,
            "Engine": "",
            "Benefit_Limits": null,
            "ClaimList": null,
            "Beneficiaries": [
                {
                    "PersonalDets": {
                        "Name": "",
                        "GivenName": "",
                        "Dob": "2021-08-02",
                        "MaritalStatus": "Single",
                        "Gender": "M",
                        "Id": "",
                        "LegalRep": {
                            "RepresentativeId": "Legal heir of insured person",
                            "RepDets": {
                                "Name": "",
                                "GivenName": "",
                                "Dob": "08-10-1986",
                                "MaritalStatus": "Married",
                                "Gender": "M",
                                "Id": "IDNumber",
                                "Nationality": "",
                                "Occupation": "",
                                "Contact": {
                                    "Email": "",
                                    "Phone": "",
                                    "FbId": "",
                                    "Typ": "",
                                    "Add": {
                                        "Add1": "",
                                        "Add2": "",
                                        "Add3": "",
                                        "Add4": "",
                                        "Add5": "",
                                        "City": "",
                                        "Country": "",
                                        "Pcode": ""
                                    },
                                    "ContactType": ""
                                }
                            },
                            "Relationship": ""
                        },
                        "Nationality": "Singaporean",
                        "Occupation": "HomeMaker",
                        "Contact": {
                            "Email": "sample@sample.com",
                            "Phone": "",
                            "FbId": "",
                            "Typ": "",
                            "Add": {
                                "Add1": "1 Thailand St.",
                                "Add2": "Street 2 of Bangkok",
                                "Add3": "District 3",
                                "Add4": "",
                                "Add5": "",
                                "City": "Bangkok",
                                "Country": "Thailand",
                                "Pcode": "1016"
                            },
                            "ContactType": ""
                        }
                    },
                    "BenefitAmt": 1000.65,
                    "Payee": {
                        "AccountName": "AcountName",
                        "Bank": "BankName",
                        "Branch": "BankBranch",
                        "Account": "AccountNo",
                        "IBAN": "IBAN",
                        "Address": {
                            "Add1": "1 Thailand St.",
                            "Add2": "Street 2 of Bangkok",
                            "Add3": "District 3",
                            "Add4": "",
                            "Add5": "",
                            "City": "Bangkok",
                            "Country": "Thailand",
                            "Pcode": "1016"
                        }
                    }
                }
            ],
            "CommissionAmount": 0,
            "ReinsuranceTreaty": "",
            "Data": [
                {
                    "PreferredLanguage": "English"
                },
                {
                    "ExtraField1": "Extra Risk Body Data",
                    "ExtraField2": "Extra Risk Body Data"
                }
            ],
            "GroupPremiumDiscount": 0,
            "StampDuty": 0,
            "Ratings": null,
            "Channel": "PRU",
            "JointLife": "",
            "RateAdjustment": 0,
            "PremiumAdjustment": 0,
            "MemberCount": 0,
            "GroupClaim": 0
        },
        "Options": null,
        "ClaimList": null,
        "AppliedPremium": null,
        "Data": null
    },
    "4": {
        "RiskType": "PA",
        "PolicyId": "",
        "RiskId": "4",
        "Riskclass": "PA",
        "CoverType": "ThaiProtect:PAThailand_v1",
        "Coverage": "",
        "Risk_Comm": "2022-08-02T07:26:06.431012Z",
        "Risk_End": "0001-01-01T00:00:00Z",
        "RiskBody": {
            "RiskType": "PA",
            "PolicyId": "",
            "RiskId": "4",
            "Plan": "PAFB-C",
            "Riskclass": "PA",
            "CoverType": "ThaiProtect:PAThailand_v1",
            "Coverage": "",
            "EventDate": "2022-08-02T07:26:06.431012Z",
            "InsuredParty": {
                "Title": "MRS.",
                "GivenName": "K",
                "Name": "Arvind Insured 3",
                "Dob": "2021-08-01",
                "Age": 1,
                "Gender": "F",
                "ClientID": "",
                "ClientType": "",
                "DataFromClient": false,
                "Id": {
                    "IDType": "National ID",
                    "IDNumber": "3453454354353",
                    "IssueDate": "2020-01-20",
                    "ExpiryDate": "2025-02-08",
                    "IssuingAgency": "abcd"
                },
                "Occupation": "Student",
                "MortalityClass": "S",
                "Kinship": "Child",
                "OtherPolicyInfo": [
                    {
                        "PolicyID": "FWD128937",
                        "Company": "FWD",
                        "SumInsured": 1500,
                        "PolicyExpiryDate": "2022-11-06T09:40:36.940647435Z",
                        "PolicyIssuanceDate": "2022-08-02T07:26:06.431012Z",
                        "Status": "In-Force",
                        "Remark": "",
                        "PolicyAge": "1"
                    }
                ],
                "Documents": [],
                "Petinfo": {},
                "Contact": {
                    "Email": "arvind.kushwaha@coherent.global",
                    "Phone": "(34)-5454-3553",
                    "FbId": "",
                    "Typ": "",
                    "Add": {
                        "Add1": "A-1408, Mount Unique",
                        "Add2": "",
                        "Add3": "Rat Burana",
                        "Add4": "Bang Pa Kok",
                        "Add5": "",
                        "City": "Bangkok",
                        "Country": "Thailand",
                        "Pcode": "10140"
                    },
                    "ContactType": ""
                },
                "Data": [
                    {
                        "PreferredLanguage": "English",
                        "Risk_Comment": "test"
                    },
                    {
                        "ExtraField1": "Extra Insured Data",
                        "ExtraField2": "Extra Insured Data"
                    }
                ]
            },
            "CoInsured": null,
            "TermUnits": "years",
            "Term": 1,
            "Sum_Insured": 12000,
            "EmpSalary": 0,
            "Payment_Frequency": "1",
            "AdditionalCover": null,
            "BasePremium": 0,
            "OptionalPremium": 0,
            "PremiumLevy": 0,
            "NetPremium": 0,
            "RefundPremium": 0,
            "RefundLevy": 0,
            "RefundGst": 0,
            "RefundNetPremium": 0,
            "Installment_Premium": 0,
            "TotalPremium": 0,
            "AppliedPremium": null,
            "LoadedPremium": null,
            "Risk_Comm": "2022-08-02T07:26:06.431012Z",
            "Risk_End": "0001-01-01T00:00:00Z",
            "Gst": 0,
            "Status": "Ready",
            "UWStatus": "",
            "Options": null,
            "Engine": "",
            "Benefit_Limits": null,
            "ClaimList": null,
            "Beneficiaries": [
                {
                    "PersonalDets": {
                        "Name": "",
                        "GivenName": "",
                        "Dob": "2021-08-01",
                        "MaritalStatus": "Single",
                        "Gender": "F",
                        "Id": "",
                        "LegalRep": {
                            "RepresentativeId": "Legal heir of insured person",
                            "RepDets": {
                                "Name": "",
                                "GivenName": "",
                                "Dob": "08-10-1986",
                                "MaritalStatus": "Married",
                                "Gender": "M",
                                "Id": "IDNumber",
                                "Nationality": "",
                                "Occupation": "",
                                "Contact": {
                                    "Email": "",
                                    "Phone": "",
                                    "FbId": "",
                                    "Typ": "",
                                    "Add": {
                                        "Add1": "",
                                        "Add2": "",
                                        "Add3": "",
                                        "Add4": "",
                                        "Add5": "",
                                        "City": "",
                                        "Country": "",
                                        "Pcode": ""
                                    },
                                    "ContactType": ""
                                }
                            },
                            "Relationship": ""
                        },
                        "Nationality": "Singaporean",
                        "Occupation": "HomeMaker",
                        "Contact": {
                            "Email": "sample@sample.com",
                            "Phone": "",
                            "FbId": "",
                            "Typ": "",
                            "Add": {
                                "Add1": "1 Thailand St.",
                                "Add2": "Street 2 of Bangkok",
                                "Add3": "District 3",
                                "Add4": "",
                                "Add5": "",
                                "City": "Bangkok",
                                "Country": "Thailand",
                                "Pcode": "1016"
                            },
                            "ContactType": ""
                        }
                    },
                    "BenefitAmt": 1000.65,
                    "Payee": {
                        "AccountName": "AcountName",
                        "Bank": "BankName",
                        "Branch": "BankBranch",
                        "Account": "AccountNo",
                        "IBAN": "IBAN",
                        "Address": {
                            "Add1": "1 Thailand St.",
                            "Add2": "Street 2 of Bangkok",
                            "Add3": "District 3",
                            "Add4": "",
                            "Add5": "",
                            "City": "Bangkok",
                            "Country": "Thailand",
                            "Pcode": "1016"
                        }
                    }
                }
            ],
            "CommissionAmount": 0,
            "ReinsuranceTreaty": "",
            "Data": [
                {
                    "PreferredLanguage": "English"
                },
                {
                    "ExtraField1": "Extra Risk Body Data",
                    "ExtraField2": "Extra Risk Body Data"
                }
            ],
            "GroupPremiumDiscount": 0,
            "StampDuty": 0,
            "Ratings": null,
            "Channel": "PRU",
            "JointLife": "",
            "RateAdjustment": 0,
            "PremiumAdjustment": 0,
            "MemberCount": 0,
            "GroupClaim": 0
        },
        "Options": null,
        "ClaimList": null,
        "AppliedPremium": null,
        "Data": null
    }
}

export const getPolicyData = () => {
    return MotorPolicyData;

    // return HSBC_PA_PolicyData;

    // return PA_PolicyData;
}
