import React, { useEffect, useCallback, useState, useRef } from 'react';
import get from 'lodash/get';
import forEach from 'lodash/forEach';
import { useForm, FormContext } from 'react-hook-form';
import Parser from 'html-react-parser';

import Field from 'common/Field';
import { IOption, IPolicy } from 'store/actions/PolicyAction';
import { CardContainer, CardInfo, CardInfoLabel, Button } from 'common/style';

interface IProps {
    data?: IPolicy;
}

const PolicyDetails: React.FC<IProps> = ({ data }) => {
    const methods = useForm();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const setValue = useCallback(methods.setValue, []);
    const [html, setHtml] = useState('');
    const f1 = useRef(null);
    const f2 = useRef(null);
    const f3 = useRef(null);

    useEffect(() => {
        if (data && data.Risks) {
            const planType: Record<string, string> = {
                Silver: 'Silver',
                Gold: 'Gold',
                Platinum: 'Platinum'
            }
            // Benefits
            setValue([
                { 'options[0]': false },
                { 'options[1]': false },
                { 'options[2]': false },
                { 'options[3]': false }
            ]);

            setValue([
                { ContID: data.ContID },
                { Product: data.Product },
                { Term: data.Term },
                { Status: data.Status },
                { Plan: planType[get(data, 'Risks[1].TravelBody.Plan')] },
                {
                    Premium: new Intl.NumberFormat('en-US', {
                        minimumFractionDigits: 2
                    }).format(get(data, 'PremiumTotals.ModalPremium', 0))
                }
            ]);

            // Set Benefit checkbox
            const benefits = get(data, 'Risks[1].TravelBody.Options', []);
            if (benefits) {
                forEach(benefits, ((opt: IOption) => {
                    switch (opt.Option) {
                        case 'Emergency':
                            setValue('options[0]', 'On');
                            break;
                        case 'Delay':
                            setValue('options[1]', 'On');
                            break;
                        case 'Baggage':
                            setValue('options[2]', 'On');
                            break;
                        case 'Personal':
                            setValue('options[3]', 'On');
                            break;
                    }
                }));
            }
        }
    }, [data, setValue])

    useEffect(() => {
        console.log("this is useeffect called for html")
        // const ss1 = { "F1": "FF1", "F2": "FF2", "Data": "eyJBMSI6IkExIiwiQTIiOiJBQTIiLCJBMyI6NjcuOTksIkE0Ijo1Ni44OX0=" };
        // const formJson = JSON.parse(atob(ss1.Data));
        // setHtml(getHTMLData(formJson));
        // setHtml('test');
        getHTMLData();
        // setTimeout(()=> {
        //     mapDataToHtml(formJson);
        // }, 2000)
    }, []);

    const getHTMLData = (): void => {
        // console.log("this is json", json);
        // const html = `<div class="form-input">
        //                     <label for="name">Field 1<span class="text-copy-0">&nbsp;&nbsp;</span></label>
        //                     <input type="text" class="form-control" id="f1" name="f1">
        //             </div>
        //             <div class="form-input pt16">
        //                     <label for="ic">Field 2<span class="text-copy-0">&nbsp;&nbsp;</span></label>
        //                     <input type="text" class="form-control" id="f2" name="f2">
        //             </div>
        //             <div class="form-input pt16">
        //                     <label for="ix">Field 3<span class="text-copy-0">&nbsp;&nbsp;</span></label>
        //                     <input type="text" class="form-control" id="f3" name="f3">
        //             </div>
        // `;
        // return html;
        // // return false;
        try {
            fetch(`https://ellwood.technology/docs/snip1.html`, {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    // 'Content-Type': 'application/json',
                }
            })
                .then((response: any) => {
                    const statusCode = response.status;
                    const data = response.text();
                    return Promise.all([statusCode, data]);
                })
                .then(([statusCode, data]) => {
                    data = `<div class="form-input">
                                        <label for="name">Field 1<span class="text-copy-0">&nbsp;&nbsp;</span></label>
                                        <input type="text" class="form-control" id="f1" name="f1">
                                </div>
                                <div class="form-input pt16">
                                        <label for="ic">Field 2<span class="text-copy-0">&nbsp;&nbsp;</span></label>
                                        <input type="text" class="form-control" id="f2" name="f2">
                                </div>
                                <div class="form-input pt16">
                                        <label for="ix">Field 3<span class="text-copy-0">&nbsp;&nbsp;</span></label>
                                        <input type="text" class="form-control" id="f3" name="f3">
                                </div>
                    `;
                    setHtml(data);
                    const ss1 = { "F1": "FF1", "F2": "FF2", "Data": "eyJBMSI6IkExIiwiQTIiOiJBQTIiLCJBMyI6NjcuOTksIkE0Ijo1Ni44OX0=" };
                    const formJson = JSON.parse(atob(ss1.Data));
                    mapDataToHtml(formJson);
                });
        } catch (exception) {
            console.log(exception);
        }


    }

    const mapDataToHtml = (dta) => {
        try {
            fetch(`https://ellwood.technology/docs/jsnip2.js`, {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    // 'Content-Type': 'application/json',
                }
            })
                .then((response: any) => {
                    const statusCode = response.status;
                    const data = response.text();
                    return Promise.all([statusCode, data]);
                })
                .then(([statusCode, data]) => {
                    // const jsResponse = `doc.getElementById("f1").value=dta.A1;
                    // doc.getElementById("f2").value=dta.A2;
                    // doc.getElementById("f3").value="ghghghgghg";
                    // `;
                    console.log("this is js response", data);
                    const jsResponse = data;
                    // console.log(document.getElementById("f1"));
                    const testFun = Function('doc', 'dta', jsResponse);
                    testFun(document, dta);
                });
        } catch (exception) {
            console.log(exception);
        }
    }

    const submitForm = () => {
        console.log(f3?.current?.value);
    }

    return (
        <FormContext {...methods}>

            {/* <CardContainer>
                <Field name="ContID" label="Policy" readonly={true} />
                <Field name="Product" label="Product" readonly={true} />
                <Field name="Term" label="Term" readonly={true} />
                <Field name="Status" label="Status" readonly={true} />
                <Field name="Plan" label="Plan Type" readonly={true} />
                <Field
                    type="dates"
                    name="startDate"
                    name2="endDate"
                    label="Start Date"
                    label2="End Date"
                    value={data?.StartDate}
                    value2={data?.EndDate}
                    readonly={true} />
                <CardInfo>
                    <CardInfoLabel>Benefit</CardInfoLabel>
                </CardInfo>
                <Field
                    type="checkbox"
                    name="options[0]"
                    value="Emergency"
                    label="Trip cancellation or Postponement" />
                <Field
                    type="checkbox"
                    name="options[1]"
                    value="Delay"
                    label="Trip cut short" />
                <Field
                    type="checkbox"
                    name="options[2]"
                    value="Baggage"
                    label="Trip diversion (1000 for every six hours diverted overseas)" />
                <Field
                    type="checkbox"
                    name="options[3]"
                    value="Personal"
                    label="Travel delay (1000 for every six hours of delay overseas and 1000 after six hours of delay in Thailand)
"/>
                <Field name="Premium" label="Premium Per Trip" placeholder="0.00" readonly={true} />
            </CardContainer> */}
            <CardContainer>
                {Parser(html)}
                <input type="text" className="form-control" id="f3" name="f3" ref={f3} />
                <div className='mt-2'>
                    <Button
                        className="primary"
                        htmlType="submit"
                        loading={false}
                        onClick={submitForm}>
                        Submit
                    </Button>
                </div>
            </CardContainer>

        </FormContext>
    )
}

export default PolicyDetails;