import React, { useEffect, useState } from 'react';
import { navigate } from 'raviger';
// import { useGlobalState } from 'store';
import endPoint from 'config/urls';
import ReceiptHeader from '../../Receipt/ReceiptHeader';
import Loader from '../../Components/Loader/Loader.componet';
import Breadcrumbs from '../../Components/Breadcrumbs/Breadcrumbs.componet';
import { ReactComponent as Sort } from 'assets/images/filter-square-fill.svg';
import { ReactComponent as ArrowUp } from 'assets/images/arrow-up.svg';
import { ReactComponent as ArrowDown } from 'assets/images/arrow-down.svg';
import { ReactComponent as CheckCircle } from 'assets/images/check-circle.svg';
import { ReactComponent as XCircle } from 'assets/images/x-circle.svg';
import { ReactComponent as Dismiss } from 'assets/images/dismiss.svg';
import { ReactComponent as Edit } from 'assets/images/Edit.svg';
import {
    AllAccountsBreadcrumbItems,
    formData,
    sampleAccount,
    showDate,
    allAccountsTableColumns,
    parseDate,
    sampleBankDets,
    sampleContactDets,
    sampleUpdateDocProps,
    sampleDocProps,
    sampleDates,
    // sampleDocProps
} from '../AccountController';
import {
    accountProps,
    searchAccountProps,
    ContactDetsProps,
    BankDetsProps,
    OtherDetsProps,
    transactionsProps,
    subAccountsProps,
    TransTableProps,
    DocListProps,
    eventProps,
    UpdateDocProps,
    dateProps,
} from '../interfaces';
import { Table, Switch, Space, Tag } from 'antd';

import 'bootstrap/dist/css/bootstrap.min.css';
import './ViewAccounts.styles.scss';

const ViewAccounts = () => {

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [accountID, setAccountID] = useState<string>();
    const [dateValues, setDateValues] = useState<dateProps>(sampleDates);
    const [currentTab, setCurrentTab] = useState<string>('Transactions');
    const [sortTable, setSortTable] = useState<string>('transView');
    const [sortDocTable, setSortDocTable] = useState<string>('docView');
    const [successMessage, setSuccessMessage] = useState<string>('Creation');
    const [showDropdown, setShowDropdown] = useState<boolean>(false);
    const [showChildDoc, setShowChildDoc] = useState<boolean>(false);
    const [showHidePopup, setShowHidePopup] = useState<boolean>(false);
    const [showHideEditPopup, setShowHideEditPopup] = useState<boolean>(false);
    const [showSuccessPopup, setShowSuccessPopup] = useState<boolean>(false);
    const [mainAccountID, setMainAccountID] = useState<string>("");
    const [searchString, setSearchString] = useState<string>("");
    const [bankDetails, setBankDetails] = useState<BankDetsProps>(sampleBankDets);
    const [docList, setDocList] = useState<DocListProps[]>(sampleDocProps);
    const [filteredDocList, setFilteredDocList] = useState<DocListProps[]>(sampleDocProps);
    const [documentName, setDocumentName] = useState<string>("");
    // const [documentNotes, setDocumentNotes] = useState<string>("");
    const [editDocUsed, setEditDocUsed] = useState<UpdateDocProps>(sampleUpdateDocProps);
    const [editDocumentName, setEditDocumentName] = useState<string>("");
    const [editNotes, setEditNotes] = useState<string>("");

    const [checkedTransArr, setCheckedTransArr] = useState<[]>([]);
    const [checkedFilteredTransArr, setCheckedFilteredTransArr] = useState<[]>([]);
    const [checkedSubTransArr, setCheckedSubTransArr] = useState<[]>([]);
    const [docData, setDocData] = useState<transactionsProps[]>([]);
    const [contactDetails, setContactDetails] = useState<ContactDetsProps>(sampleContactDets);
    const [accountInformation, setAccountInformation] = useState<accountProps>(sampleAccount);
    const [accountTransList, setAccountTransList] = useState<transactionsProps[]>([]);
    const [filteredTransactions, setFilteredTransactions] = useState<transactionsProps[]>([]);
    const [subAccountList, setSubAccountList] = useState<subAccountsProps>({});
    const [accountType, setAccountType] = useState<string>("");

    useEffect(() => {
        const urlPath = window.location.pathname.split('/');
        if (urlPath.length > 2) {
            const accountId = urlPath[2];
            getAccountDetails(accountId);
            setMainAccountID(accountId);
            getTransactions(accountId);
            getDocuments(accountId);
            setAccountID(accountId);
        }
    }, []);

    const getAccountDetails = async (accountId: string) => {
        setIsLoading(true);
        try {
            await fetch(`${endPoint.getAccount}?search_by=AccountID&value=${accountId}&limit=1&order_by=AccountID_DESC`, {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                }
            })
                .then((response: any) => {
                    const statusCode = response.status;
                    const data = response.json();
                    return Promise.all([statusCode, data]);
                })
                .then(([statusCode, data]) => {
                    setIsLoading(false);
                    if (statusCode === 200 && data.length > 0) {
                        console.log('response', data);
                        const BankDetails = JSON.parse(data[0].Data)["BankDets"];
                        const Contact = JSON.parse(data[0].ContactDets);
                        getLedgerData(data[0].LedgerID);
                        console.log(BankDetails);
                        setBankDetails(BankDetails);
                        setContactDetails(Contact);
                        setAccountInformation(data[0]);
                    }
                });
        } catch (exception) {
            setIsLoading(false);
            console.log(exception);
        }
    }

    const getLedgerData = async (LedgerId:string) => {
        setIsLoading(true);
        try {
            await fetch(`${endPoint.getOneLedger}${LedgerId}`, {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                }
            })
                .then((response: any) => {
                    const statusCode = response.status;
                    const data = response.json();
                    return Promise.all([statusCode, data]);
                })
                .then(([statusCode, data]) => {
                    setIsLoading(false);
                    if (statusCode === 200) {
                        console.log('this is ledger response', data);
                        setAccountType(data.Tag1);
                    }
                });
        } catch (exception) {
            setIsLoading(false);
            console.log(exception);
        }
    }
    const getDocuments = async (accountId: string) => {
        setIsLoading(true);
        try {
            await fetch(`${endPoint.getDocuments}?value=${accountId}&search_by=AccountID`, {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                }
            })
                .then((response: any) => {
                    const statusCode = response.status;
                    const data = response.json();
                    return Promise.all([statusCode, data]);
                })
                .then(([statusCode, data]) => {
                    setIsLoading(false);
                    if (statusCode === 200) {
                        const documents = data.map((d: any) => {
                            d.showHideChildDoc = false;
                            d.docTransList = [];
                            return d;
                        })
                        console.log("this is doc data", documents);
                        setDocList(documents);
                        // setFilterd(documents)
                    }
                });
        } catch (exception) {
            setIsLoading(false);
            console.log(exception);
        }
    }

    const FilteredDocumentsData = (searchData: any) => {
        let data = docList;
        const result = data.filter(e => {
            return (e.DocumentID.toLowerCase().includes(searchData.toLowerCase()) ||
                e.DocumentType.toLowerCase().includes(searchData.toLowerCase()) ||
                e.Status.toLowerCase().includes(searchData.toLowerCase())
            )
        }
        );
        console.log(result);
        setFilteredDocList(result);
        if (result.length > 0) {
            setSortDocTable("docFilterView");
        }
    }

    const getTransactions = async (accountId: string) => {
        setIsLoading(true);
        try {
            await fetch(`${endPoint.getAccount}transaction?order_by=TranID&value=${accountId}&search_by=AccountID`, {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                }
            })
                .then((response: any) => {
                    const statusCode = response.status;
                    const data = response.json();
                    return Promise.all([statusCode, data]);
                })
                .then(([statusCode, data]) => {
                    setIsLoading(false);
                    if (statusCode === 200 && data.length > 0) {
                        console.log("this is my data", data);
                        const transList = data.map((d: any) => {
                            d.showHideTransChild = false;
                            return d;
                        })
                        setAccountTransList(transList);
                        const SubAccounts: subAccountsProps = {};
                        // const TransTable: TransTableProps = {}
                        data.forEach((sub: transactionsProps) => {

                            if (sub.SubAccount in SubAccounts) {
                                SubAccounts[sub.SubAccount].Amount += Number(sub.Amount);
                                SubAccounts[sub.SubAccount].Transactions.push(sub);
                            } else {
                                SubAccounts[sub.SubAccount] = {
                                    Amount: sub.Amount,
                                    Transactions: [sub],
                                    ShowHideChild: false
                                }
                            }

                            // if (sub.SonicTranID in TransTable) {
                            //     TransTable[sub.SonicTranID].Amount += Number(sub.Amount);
                            //     TransTable[sub.SonicTranID].Transactions.push(sub);
                            // } else {
                            //     TransTable[sub.SonicTranID] = {
                            //         Amount: sub.Amount,
                            //         Transactions: [sub],
                            //         ShowHideChild: false
                            //     }
                            // }

                        }
                        );
                        console.log('AccountList', SubAccounts);
                        // console.log('Translist', TransTable);
                        setSubAccountList(SubAccounts);
                        // setTransactionList(TransTable);
                    }
                });
        } catch (exception) {
            setIsLoading(false);
            console.log(exception);
        }
    }

    const DateFilter = (filteredResult: transactionsProps[], filterDates: dateProps) => {
        let minDate = new Date(filterDates.FromDate);
        let maxDate = new Date(filterDates.ToDate);

        const DateFilteredData = filteredResult.filter(d => {
            let postDate = new Date(d.PostingDate);
            if (filterDates.FromDate && filterDates.ToDate) {
                return (postDate >= minDate && postDate <= maxDate)
            } else if (filterDates.FromDate) {
                return (postDate >= minDate)
            } else if (filterDates.ToDate) {
                return (postDate <= maxDate)
            }
        })
        setFilteredTransactions(DateFilteredData);

        setSortTable("filteredTransView");




    }
    const FilteredTransactionData = (searchData: string, fieldName: string, filterDates: dateProps) => {
        let result = accountTransList;
        if (fieldName === 'search-input' && searchData !== '') {
            result = accountTransList.filter(e => {
                return (e.SubAccount.toLowerCase().includes(searchData.toLowerCase()) ||
                    e.SonicTranID.toLowerCase().includes(searchData.toLowerCase()) ||
                    e.TagRef1.toLowerCase().includes(searchData.toLowerCase()) ||
                    e.Status.toLowerCase().includes(searchData.toLowerCase())
                )
            });
        }

        if (fieldName === 'from-date' || fieldName === 'to-date') {
            DateFilter(result, filterDates);
        } else {
            setFilteredTransactions(result);
        }
        if (result.length > 0) {
            setSortTable("filteredTransView");
        } else {
            setSortTable("transView");
        }
    }

    const applyFilter = (e: eventProps) => {
        const { value, name } = e.target;
        const tempDates = { ...dateValues };

        if (name === 'search-input') {
            tempDates.searchData = value;
            setDateValues(tempDates);
            setSearchString(value);
        }
        if (name === 'from-date') {
            tempDates.FromDate = value;
            setDateValues(tempDates);

        }
        if (name === 'to-date') {
            tempDates.ToDate = value;
            setDateValues(tempDates);

        }
        if (currentTab === "Transactions") {
            FilteredTransactionData(searchString, name, tempDates);
        } else if (currentTab === "Documents") {
            FilteredDocumentsData(searchString);
        }
    }




    const handleUpdate = (e: eventProps, index: number, TranID: number, subAccount: any) => {
        const { value, name } = e.target;
        if (name === 'transCheck') {
            const newTransArr: any[] = [...checkedTransArr];
            let transChecked;
            // @ts-ignore
            transChecked = e.target.checked;

            const selectedTrans = accountTransList[index];
            selectedTrans.TranID = accountTransList[index].TranID;

            if (transChecked) {
                newTransArr.push(String(selectedTrans.TranID));
            } else {
                const TransIndex = newTransArr.indexOf(String(selectedTrans.TranID));
                if (TransIndex > -1) {
                    newTransArr.splice(TransIndex, 1);
                }
            }
            setCheckedTransArr(newTransArr);
        }
        else if (name === 'filteredTransCheck') {
            const newFilteredTransArr: any[] = [...checkedFilteredTransArr];
            let FilteredTransChecked;
            // @ts-ignore
            FilteredTransChecked = e.target.checked;

            const selectedTrans = filteredTransactions[index];
            selectedTrans.TranID = filteredTransactions[index].TranID;

            if (FilteredTransChecked) {
                newFilteredTransArr.push(String(selectedTrans.TranID));
            } else {
                const TransIndex = newFilteredTransArr.indexOf(String(selectedTrans.TranID));
                if (TransIndex > -1) {
                    newFilteredTransArr.splice(TransIndex, 1);
                }
            }
            setCheckedFilteredTransArr(newFilteredTransArr);
        } else if (name === 'subTransCheck') {
            const newSubTransArr: any[] = [...checkedSubTransArr];
            let subTransChecked;

            // @ts-ignore
            subTransChecked = e.target.checked;

            const selectedTrans = subAccountList[subAccount].Transactions[index];
            selectedTrans.TranID = subAccountList[subAccount].Transactions[index].TranID;

            if (subTransChecked) {

                newSubTransArr.push(String(selectedTrans.TranID));
            } else {
                const subTransIndex = newSubTransArr.indexOf(String(selectedTrans.TranID));
                if (subTransIndex > -1) {
                    newSubTransArr.splice(subTransIndex, 1);
                }
            }
            setCheckedSubTransArr(newSubTransArr);
        }
    }
    const handleChange = (e: eventProps) => {
        const { value, name } = e.target;
        if (name === 'DocName' && value !== '') {
            setDocumentName(value);
        }
        // if (name === 'Notes' && value !== '') {
        //     setDocumentNotes(value);
        // }
        if (name === 'EditDocName') {
            console.log(value);
            setEditDocumentName(value);

            const tempDocUsed = { ...editDocUsed };
            tempDocUsed.DocumentID = value;
            setEditDocUsed(tempDocUsed)
        }
        if (name === 'EditNotes' && value !== '') {
            setEditNotes(value);
            const tempDocUsed = { ...editDocUsed };
            tempDocUsed.Notes = value;
            setEditDocUsed(tempDocUsed)
        }
    }
    const openPopup = () => {
        setShowHidePopup(true);
    }
    const closePopup = () => {
        setShowSuccessPopup(false);
        window.location.reload();
    }
    const createReceipt = () => {
        setIsLoading(true);
        let transArrList;
        if (sortTable === 'transView') {
            transArrList = checkedTransArr;
        } else if (sortTable === 'subaccount') {
            transArrList = checkedSubTransArr;
        } else if (sortTable === 'filteredTransView') {
            transArrList = checkedFilteredTransArr;
        }
        let receiptPayload =
        {
            "AccountID": accountID,
            "DocumentID": documentName,
            "DocumentType": "Receipt",
            "ListTransaction": transArrList,
            // "Notes": documentNotes
        }
        console.log("this is my payload", receiptPayload);
        fetch(`${endPoint.createDocument}/document`, {
            method: 'POST',
            body: JSON.stringify(receiptPayload),
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            }
        })
            .then((response: any) => {
                const statusCode = response.status;
                const data = response.json();
                return Promise.all([statusCode, data]);
            })
            .then(([statusCode, data]) => {
                setIsLoading(false);
                if (statusCode === 200) {
                    setShowHidePopup(false);
                    setShowSuccessPopup(true);
                    setSuccessMessage('Creation')
                    console.log("receipt created", data);

                }
            })
            .catch((exception) => {
                setIsLoading(false);
                console.log(exception);
            });
    }

    const showHideDropdown = () => {
        setShowDropdown(!showDropdown);
    }
    const selectSortValue = (val: string) => {
        console.log("this is sort value", val);
        setSortTable(val);
        setShowDropdown(false);
    }
    const showHideTransactions = (subAccountName: string) => {
        const newSubAccounts = { ...subAccountList };
        newSubAccounts[subAccountName].ShowHideChild = !newSubAccounts[subAccountName].ShowHideChild;
        setSubAccountList(newSubAccounts);
    }
    const ToggleShowHide = (index: number) => {
        const tempDocList = [...docList];
        tempDocList[index].showHideChildDoc = !tempDocList[index].showHideChildDoc
        setDocList(tempDocList);
    }
    const showTransactionChild = (index: number) => {
        const tempTransList = [...accountTransList];
        tempTransList[index].showHideTransChild = !tempTransList[index].showHideTransChild
        setAccountTransList(tempTransList);
    }

    const showHideDocTransaction = (docId: string, index: number) => {
        if (docList[index].showHideChildDoc === true) {
            ToggleShowHide(index);
            return false;
        }
        setIsLoading(true);
        fetch(`${endPoint.getAccount}transaction?order_by=TranID&search_by=TagRef1&value=${docId}`, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            }
        })
            .then((response: any) => {
                const statusCode = response.status;
                const data = response.json();
                return Promise.all([statusCode, data]);
            })
            .then(([statusCode, data]) => {
                setIsLoading(false);
                if (statusCode === 200) {
                    console.log("this is tag ref data", data);
                    setDocData(data);
                    const tempDocList = [...docList];
                    tempDocList[index].docTransList = data;
                    tempDocList[index].showHideChildDoc = true;
                    setDocList(tempDocList);
                    // ToggleShowHide(index);
                    // setShowChildDoc(!showChildDoc);
                }
            })
            .catch((exception) => {
                setIsLoading(false);
                console.log(exception);
            });

    }


    const showHideSonicTransactions = (subAccountName: string, TranID: number) => {
        const newSubAccounts = { ...subAccountList };
        const tempSubAccTransactions = newSubAccounts[subAccountName].Transactions.filter(T1 => {
            if (T1.TranID === TranID) {
                T1.ShowSonicTranList = !T1.ShowSonicTranList;
            }
            return T1;
        });
        newSubAccounts[subAccountName].Transactions = tempSubAccTransactions;
        // newSubAccounts[subAccountName].ShowHideChild = !newSubAccounts[subAccountName].ShowHideChild;
        setSubAccountList(newSubAccounts);
    }

    const AccountTab = (tab: string) => {
        setCurrentTab(tab);
    }
    const editDocument = () => {
        const tempEditPayload = { ...editDocUsed }
        tempEditPayload.Notes = editNotes;
        setEditDocUsed(tempEditPayload);
        console.log("this is my payload", editDocUsed);

        setIsLoading(true);
        fetch(`${endPoint.getDocuments}`, {
            method: 'PUT',
            body: JSON.stringify(editDocUsed),
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            }

        })
            .then((response: any) => {
                const statusCode = response.status;
                const data = response.json();
                return Promise.all([statusCode, data]);
            })
            .then(([statusCode, data]) => {
                setIsLoading(false);
                if (statusCode === 200) {
                    console.log("document edited", data);
                    setShowHideEditPopup(false);
                    setShowSuccessPopup(true);
                    setSuccessMessage('Edit');
                }
            })
            .catch((exception) => {
                setIsLoading(false);
                console.log(exception);
            });
    }
    const setDocumentAction = (Doc: any, status: string) => {
        console.log("this is document", Doc);

        if (status === "Approved") {
            Doc.Status = "Approved";
            console.log("this is approved document", Doc);

            setIsLoading(true);
            fetch(`${endPoint.getDocuments}/approve`, {
                method: 'POST',
                body: JSON.stringify(Doc),
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                }

            })
                .then((response: any) => {
                    const statusCode = response.status;
                    const data = response.json();
                    return Promise.all([statusCode, data]);
                })
                .then(([statusCode, data]) => {
                    setIsLoading(false);
                    if (statusCode === 200) {
                        console.log("Document approved", data);
                        setShowSuccessPopup(true);
                        setSuccessMessage('Approve')
                    }
                })
                .catch((exception) => {
                    setIsLoading(false);
                    console.log(exception);
                });
        } else if (status === "Rejected") {
            Doc.Status = "Rejected";
            console.log("this is rejected document", Doc);

            setIsLoading(true);
            fetch(`${endPoint.getDocuments}`, {
                method: 'PUT',
                body: JSON.stringify(Doc),
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                }

            })
                .then((response: any) => {
                    const statusCode = response.status;
                    const data = response.json();
                    return Promise.all([statusCode, data]);
                })
                .then(([statusCode, data]) => {
                    setIsLoading(false);
                    if (statusCode === 200) {
                        console.log("Document rejected", data);
                        setShowSuccessPopup(true);
                        setSuccessMessage('Reject')
                    }
                })
                .catch((exception) => {
                    setIsLoading(false);
                    console.log(exception);
                });
        } else if (status === "Edit") {
            console.log("this is edit document", Doc);
            setShowHideEditPopup(true);
            setEditDocUsed(Doc);

        }

    }
    // console.log("this is my doc arr", docList);
    // console.log("this is my subaccount arr", subAccountList);
    // console.log("this is my trans arr", checkedTransArr);
    // console.log("this is my sub trans arr", checkedSubTransArr);
    // console.log("this is my filtered", filteredTransactions);
    console.log("this is my account Type", accountType);


    return (
        <div id="view-accounts1-container">
            {
                isLoading && (
                    <Loader />
                )
            }
            <ReceiptHeader />
            <div>
                <div className="breadcrumbs">
                    <Breadcrumbs paths={AllAccountsBreadcrumbItems} />
                </div>
                <div className='header-box'>
                    <table className='table table-responsive table-borderless'>
                        <thead>
                            <tr>
                                <th>Account ID</th>
                                <th>Total Dr. Amount</th>
                                <th>Total Cr. Amount</th>
                                <th>Net Ammount</th>
                                <th>Status</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>{mainAccountID}</td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td>{accountInformation.Status}</td>
                            </tr>
                        </tbody>
                    </table>
                    <nav className='nav'>
                        <ul className='nav-list mb-0'>
                            {/* <li onClick={() => AccountTab('Sub_Accounts')} className={`${(currentTab === 'Sub_Accounts') ? 'tab-active' : ''}`} ><a>Sub Accounts</a></li> */}
                            <li onClick={() => AccountTab('Transactions')} className={`${(currentTab === 'Transactions') ? 'tab-active' : ''}`}><a>Transactions</a></li>
                            <li onClick={() => AccountTab('Documents')} className={`${(currentTab === 'Documents') ? 'tab-active' : ''}`}><a>Documents</a></li>
                        </ul>
                    </nav>
                </div>
                <div className='filter-box m-5'>
                    <div>
                        <input type='text' className='form-control m-2' name="search-input" id="search-input" value={dateValues.searchData} onChange={(e) => applyFilter(e)}></input>
                    </div>
                    <div className='d-flex align-items-center'>
                        <div>
                            <input type='date' className='form-control m-2' id="from-date" name="from-date" value={dateValues.FromDate} onChange={(e) => applyFilter(e)}></input>
                        </div>
                        <div className='ml-3 mr-2'>To</div>
                        <div>
                            <input type='date' className='form-control m-2' id="to-date" name="to-date" value={dateValues.ToDate} onChange={(e) => applyFilter(e)}></input>
                        </div>

                    </div>
                    <div className='align-items-center'>
                        <Sort onClick={showHideDropdown} data-toggle="tooltip" data-placement="bottom" title="Sort by" style={{ cursor: "pointer" }}></Sort>
                    </div>
                </div>
                {
                    showDropdown && (
                        <ul className="sort-list list-unstyled">
                            <li style={{ borderBottom: "0.5px solid #ccc" }}>Group by</li>
                            <li onClick={() => selectSortValue('transView')}>Transaction View</li>
                            <li onClick={() => selectSortValue('subaccount')}>Sub-Accounts</li>
                        </ul>
                    )
                }
                {
                    sortTable === 'filteredTransView' && checkedFilteredTransArr && checkedFilteredTransArr.length > 0 && (
                        <div className='receipt-box m-5'>
                            <div>
                                <b>{checkedFilteredTransArr.length} items selected</b>
                            </div>
                            <div>
                                <input type="button" className='blue-button ml-4' value={`Create ${accountType}`} onClick={() => openPopup()} />
                            </div>
                        </div>
                    )
                }
                {
                    sortTable === 'transView' && checkedTransArr && checkedTransArr.length > 0 && (
                        <div className='receipt-box m-5'>
                            <div>
                                <b>{checkedTransArr.length} items selected</b>
                            </div>
                            <div>
                                <input type="button" className='blue-button ml-4' value={`Create ${accountType}`} onClick={() => openPopup()} />
                            </div>
                        </div>
                    )
                }
                {
                    sortTable === 'subaccount' && checkedSubTransArr && checkedSubTransArr.length > 0 && (
                        <div className='receipt-box m-5'>
                            <div>
                                <b>{checkedSubTransArr.length} items selected</b>
                            </div>
                            <div>
                                <input type="button" className='blue-button ml-4' value={`Create ${accountType}`} onClick={() => openPopup()} />
                            </div>
                        </div>
                    )
                }

                {
                    currentTab === 'Transactions' && (
                        <>
                            <div className='table-box m-5'>
                                {
                                    sortTable === 'transView' && (
                                        <table className='table table-borderless'>
                                            <thead>
                                                <tr>
                                                    <th></th>
                                                    <th>Transaction ID</th>
                                                    <th>Sub Account</th>
                                                    <th>{accountType} Number</th>
                                                    <th>Transaction Type</th>
                                                    <th>Transaction Date</th>
                                                    <th>Status</th>
                                                    <th>Amount</th>
                                                    <th>Balanace</th>
                                                    <th></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    accountTransList.map((trans, index) => (
                                                        <>
                                                            <tr key={index} onClick={() => showTransactionChild(index)}>
                                                                <td className="checkbox-input">
                                                                    <input type='checkbox' name="transCheck" onChange={e => handleUpdate(e, index, trans.TranID, "")} disabled={trans.TagRef1 ? true : false}></input>
                                                                </td>
                                                                <td>{trans.SonicTranID}</td>
                                                                <td>{trans.SubAccount}</td>
                                                                <td>{trans.TagRef1}</td>
                                                                <td>{trans.TranType}</td>
                                                                <td>{showDate(trans.TimeStamp)}</td>
                                                                <td>{trans.Status}</td>
                                                                <td>{trans.Amount}</td>
                                                                <td>0.00</td>
                                                                <td>{trans.showHideTransChild ? <ArrowUp /> : <ArrowDown />}</td>
                                                            </tr>
                                                            {
                                                                trans.showHideTransChild && trans.Data && (
                                                                    <tr>
                                                                        <td colSpan={10}>Some Data</td>
                                                                    </tr>
                                                                )

                                                            }

                                                        </>
                                                    ))
                                                }

                                            </tbody>
                                        </table>
                                    )}
                                {
                                    sortTable === 'filteredTransView' && (
                                        <table className='table table-borderless'>
                                            <thead>
                                                <tr>
                                                    <th></th>
                                                    <th>Transaction ID</th>
                                                    <th>Sub Account</th>
                                                    <th>{accountType}</th>
                                                    <th>Transaction Type</th>
                                                    <th>Transaction Date</th>
                                                    <th>Status</th>
                                                    <th>Amount</th>
                                                    <th>Balanace</th>
                                                    <th></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    filteredTransactions.map((trans, index) => (
                                                        <>
                                                            <tr key={index} onClick={() => showTransactionChild(index)}>
                                                                <td className="checkbox-input">
                                                                    <input type='checkbox' name="filteredTransCheck" onChange={e => handleUpdate(e, index, trans.TranID, "")} disabled={trans.TagRef1 ? true : false}></input>
                                                                </td>
                                                                <td>{trans.SonicTranID}</td>
                                                                <td>{trans.SubAccount}</td>
                                                                <td>{trans.TagRef1}</td>
                                                                <td>{trans.TranType}</td>
                                                                <td>{showDate(trans.TimeStamp)}</td>
                                                                <td>{trans.Status}</td>
                                                                <td>{trans.Amount}</td>
                                                                <td>0.00</td>
                                                                <td>{trans.showHideTransChild ? <ArrowUp /> : <ArrowDown />}</td>
                                                            </tr>
                                                            {
                                                                trans.showHideTransChild && trans.Data && (
                                                                    <tr>
                                                                        <td colSpan={10}></td>
                                                                    </tr>
                                                                )

                                                            }

                                                        </>
                                                    ))
                                                }

                                            </tbody>
                                        </table>
                                    )}

                                {
                                    sortTable === 'subaccount' && (
                                        <table className='table table-borderless' >
                                            <thead>
                                                <tr>
                                                    <th></th>
                                                    <th>Transaction ID</th>
                                                    <th>Sub Account</th>
                                                    <th>{accountType}</th>
                                                    <th>Transaction Type</th>
                                                    <th>Transaction Date</th>
                                                    <th>Amount</th>
                                                    <th>Balance</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    Object.keys(subAccountList).map((subAccount, index) => (
                                                        <React.Fragment>
                                                            <tr key={index} onClick={() => showHideTransactions(subAccount)} style={{ cursor: 'pointer', background: "#FAFAFA" }}>
                                                                {/* <td className="checkbox-input"><input type='checkbox'></input></td> */}
                                                                <td className="checkbox-input"></td>
                                                                <td colSpan={6}><b>{subAccount}</b></td>
                                                                <td style={{ textAlign: "right" }}>
                                                                    {subAccountList[subAccount].ShowHideChild ? <ArrowUp /> : <ArrowDown />}
                                                                </td>
                                                            </tr>

                                                            {
                                                                subAccountList[subAccount].ShowHideChild && (
                                                                    <React.Fragment>
                                                                        {
                                                                            subAccountList[subAccount].Transactions.map((Trans, subIndex) => (

                                                                                <tr onClick={() => showHideSonicTransactions(subAccount, Trans.TranID)} style={{ cursor: 'pointer' }}>
                                                                                    <td className="checkbox-input" ><input type='checkbox' name="subTransCheck" onChange={e => handleUpdate(e, subIndex, Trans.TranID, subAccount)} disabled={Trans.TagRef1 ? true : false} ></input></td>
                                                                                    <td>{Trans.TranID}</td>
                                                                                    <td>{Trans.SubAccount}</td>
                                                                                    <td>{Trans.TagRef1}</td>
                                                                                    <td>{Trans.TranType}</td>
                                                                                    <td>{showDate(Trans.TimeStamp)}</td>
                                                                                    <td>{Trans.Amount}</td>
                                                                                    <td>0.00</td>
                                                                                </tr>
                                                                            )


                                                                            )
                                                                        }
                                                                    </React.Fragment>
                                                                )}
                                                        </React.Fragment>
                                                    ))
                                                }

                                            </tbody>
                                        </table>
                                    )
                                }

                            </div>
                            <br />
                        </>

                    )
                }



                {
                    currentTab === 'Documents' && (
                        <>
                            <div className='table-box m-5'>
                                {
                                    sortDocTable === 'docView' && (
                                        <table className='table table-borderless'>
                                            <thead>
                                                <tr>

                                                    <th>Document ID</th>
                                                    <th>Documents Type</th>
                                                    <th>Date</th>
                                                    <th>Status</th>
                                                    <th style={{ textAlign: "center" }}>Action</th>
                                                    <th></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    docList.map((doc, index) => (
                                                        <React.Fragment>
                                                            <tr key={index} style={{ cursor: 'pointer' }}>
                                                                <td>{doc.DocumentID}</td>
                                                                <td>{doc.DocumentType}</td>
                                                                <td>{showDate(doc.CreateDate)}</td>
                                                                <td>{doc.Status}</td>
                                                                <td>
                                                                    <div className='actions-flex'>
                                                                        <CheckCircle style={{ color: "#3ECB0C" }} onClick={() => setDocumentAction(doc, "Approved")} />
                                                                        <XCircle style={{ color: "#D31145" }} onClick={() => setDocumentAction(doc, "Rejected")} />
                                                                        <Edit style={{ width: "1rem", height: "1rem" }} onClick={() => setDocumentAction(doc, "Edit")} />
                                                                    </div>
                                                                </td>
                                                                <td onClick={() => showHideDocTransaction(doc.DocumentID, index)} >{showChildDoc ? <ArrowUp /> : <ArrowDown />}</td>

                                                            </tr>
                                                            {
                                                                doc.showHideChildDoc && (
                                                                    <tr>
                                                                        <td colSpan={7}>
                                                                            <div className='table-box m-3'>
                                                                                <table className='table table-borderless sub-account-table' >
                                                                                    <thead>
                                                                                        <tr>
                                                                                            <th>Transaction ID</th>
                                                                                            <th>Sub Account</th>
                                                                                            <th>{accountType} Number</th>
                                                                                            <th>Transaction Type</th>
                                                                                            <th>Transaction Date</th>
                                                                                            <th>Amount</th>
                                                                                            <th>Balanace</th>
                                                                                        </tr>
                                                                                    </thead>
                                                                                    <tbody>
                                                                                        {
                                                                                            doc.docTransList.map(Trans => (
                                                                                                <tr>
                                                                                                    <td>{Trans.TranID}</td>
                                                                                                    <td>{Trans.SubAccount}</td>
                                                                                                    <td>{Trans.TagRef1}</td>
                                                                                                    <td>{Trans.TranType}</td>
                                                                                                    <td>{showDate(Trans.TimeStamp)}</td>
                                                                                                    <td>{Trans.Amount}</td>
                                                                                                    <td>0.00</td>
                                                                                                </tr>
                                                                                            ))
                                                                                        }
                                                                                    </tbody>
                                                                                </table>
                                                                            </div>

                                                                        </td>
                                                                    </tr>
                                                                )
                                                            }



                                                        </React.Fragment>
                                                    ))
                                                }

                                            </tbody>
                                        </table>
                                    )
                                }
                                {
                                    sortDocTable === 'docFilterView' && (
                                        <table className='table table-borderless'>
                                            <thead>
                                                <tr>

                                                    <th>Document ID</th>
                                                    <th>Documents Type</th>
                                                    <th>Date</th>
                                                    <th>Status</th>
                                                    <th style={{ textAlign: "center" }}>Action</th>
                                                    <th></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    filteredDocList.map((doc, index) => (
                                                        <React.Fragment>
                                                            <tr key={index} style={{ cursor: 'pointer' }}>
                                                                <td>{doc.DocumentID}</td>
                                                                <td>{doc.DocumentType}</td>
                                                                <td>{showDate(doc.CreateDate)}</td>
                                                                <td>{doc.Status}</td>
                                                                <td>
                                                                    <div className='actions-flex'>
                                                                        <CheckCircle style={{ color: "#3ECB0C" }} onClick={() => setDocumentAction(doc, "Approved")} />
                                                                        <XCircle style={{ color: "#D31145" }} onClick={() => setDocumentAction(doc, "Rejected")} />
                                                                        <Edit style={{ width: "1rem", height: "1rem" }} onClick={() => setDocumentAction(doc, "Edit")} />
                                                                    </div>
                                                                </td>
                                                                <td onClick={() => showHideDocTransaction(doc.DocumentID, index)} >{showChildDoc ? <ArrowUp /> : <ArrowDown />}</td>

                                                            </tr>
                                                            {
                                                                doc.showHideChildDoc && (
                                                                    <tr>
                                                                        <td colSpan={7}>
                                                                            <div className='table-box m-3'>
                                                                                <table className='table table-borderless sub-account-table' >
                                                                                    <thead>
                                                                                        <tr>
                                                                                            <th>Transaction ID</th>
                                                                                            <th>Invoice Number</th>
                                                                                            <th>Transaction Type</th>
                                                                                            <th>Transaction Date</th>
                                                                                            <th>Amount</th>
                                                                                            <th>Balanace</th>
                                                                                        </tr>
                                                                                    </thead>
                                                                                    <tbody>
                                                                                        {
                                                                                            doc.docTransList.map(Trans => (
                                                                                                <tr>
                                                                                                    <td>{Trans.TranID}</td>
                                                                                                    <td>{Trans.SubAccount}</td>
                                                                                                    <td>{Trans.TranType}</td>
                                                                                                    <td>{showDate(Trans.TimeStamp)}</td>
                                                                                                    <td>{Trans.Amount}</td>
                                                                                                    <td>0.00</td>
                                                                                                </tr>
                                                                                            ))
                                                                                        }
                                                                                    </tbody>
                                                                                </table>
                                                                            </div>

                                                                        </td>
                                                                    </tr>
                                                                )
                                                            }



                                                        </React.Fragment>
                                                    ))
                                                }

                                            </tbody>
                                        </table>
                                    )
                                }


                            </div>

                        </>
                    )
                }

                <br />

                {
                    showHidePopup && (
                        <div className="modal" id="myModal">
                            <div className="modal-content">
                                <div className="title-container">
                                    New Document
                                </div>
                                <div className="form-container">
                                    <div>
                                        <label>Document Id</label>
                                        <input type="text" name="DocName" id="DocName" onChange={(e) => handleChange(e)} className='form-control' />
                                    </div>
                                    <div>
                                        <label>Notes</label>
                                        <input type="text" name="Notes" id="Notes" onChange={(e) => handleChange(e)} className='form-control' />
                                    </div>
                                </div>
                                <div className="crete-doc-container">
                                    <div className='d-flex justify-content-space-between'>
                                        <button className='cancel-button' onClick={() => setShowHidePopup(false)}>Cancel</button>
                                        <button className='blue-button ml-3' onClick={createReceipt}>Save {accountType}</button>
                                    </div>

                                </div>
                            </div>
                        </div>
                    )
                }
                {
                    showHideEditPopup && (
                        <div className="modal" id="myModal">
                            <div className="modal-content">
                                <div className="title-container">
                                    Edit Document
                                </div>
                                <div className="form-container">
                                    {/* <div>
                                        <label>Document Id</label>
                                        <input type="text" name="EditDocName" id="EditDocName" onChange={(e) => handleChange(e)} value={editDocUsed.DocumentID} className='form-control' />
                                    </div> */}
                                    <div>
                                        <label>Notes</label>
                                        <input type="text" name="EditNotes" id="EditNotes" onChange={(e) => handleChange(e)} value={editDocUsed.Notes} className='form-control' />
                                    </div>
                                </div>
                                <div className="crete-doc-container">
                                    <div className='d-flex justify-content-space-between'>
                                        <button className='cancel-button' onClick={() => setShowHideEditPopup(false)}>Cancel</button>
                                        <button className='blue-button ml-3' onClick={editDocument}>Save Document</button>
                                    </div>

                                </div>
                            </div>
                        </div>
                    )
                }
                {
                    showSuccessPopup && (
                        <div className="modal" id="myModal">
                            <div className="popup-box">
                                <div>
                                    <CheckCircle />

                                    <span className='ml-2'>
                                        {
                                            successMessage === "Creation" ? "Document created successfully" :
                                                successMessage === "Approve" ? "Document approved successfully" :
                                                    successMessage === "Reject" ? "Document rejected successfully" :
                                                        successMessage === "Edit" ? "Document edited successfully" : ""
                                        }


                                    </span>
                                </div>
                                <div>
                                    <Dismiss style={{ cursor: 'pointer' }} onClick={closePopup} />
                                </div>
                            </div>
                        </div>
                    )
                }


            </div>
        </div >
    )
}

export default ViewAccounts;