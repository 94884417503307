import React, { useEffect, useState } from 'react';
import map from 'lodash/map';
import get from 'lodash/get';
import Moment from 'moment';
import { Tooltip, Popconfirm, message } from 'antd';

import { IPolicy, IBasicRisk, removeRisk, getPolicy } from 'store/actions/PolicyAction';
import { TableContainer } from 'Pages/Policy/style';
import RiskModal from 'Pages/Policy/components/Modals/RiskModal';
import { apiRequest, useGlobalState } from 'store';
import Chart from 'Pages/Policy/components/Chart';
import { getMappingJson } from '../../../common/Utils/mapping-data';
import { getPolicyData } from '../../../common/Utils/policy-data';

interface IProps {
    data?: IPolicy;
}

interface ICoverage {
    RiskId: string;
    StartDate?: string;
    EndDate?: string;
}

const Coverage: React.FC<IProps> = ({ data }) => {
    const [tableData, setTableData] = useState<Array<ICoverage>>([]);
    const [editModal, setEditModal] = useState(false);
    const [riskData, setRiskData] = useState<IBasicRisk>()
    const [totalAmount, setTotalAmount] = useState(0)
    const [riskDates, setRiskDates] = useState<Array<Array<Moment.Moment>>>([]);
    const [{ fetching }] = useGlobalState('PolicyReducer');
    const [mappingData] = useState(getMappingJson());
    const [policyData] = useState(getPolicyData());

    useEffect(() => {
        if (data && data.Risks) {
            let amount = 0;
            let dates: Array<Array<Moment.Moment>> = [];

            setTableData(map(data.Risks, (risk: IBasicRisk) => {
                amount += risk.RiskBody?.TotalPremium || 0;
                dates.push([
                    Moment(risk.RiskBody?.Risk_Comm).startOf('day'),
                    Moment(risk.RiskBody?.Risk_End).startOf('day')
                ])
                return {
                    RiskId: risk.RiskId,
                    StartDate: Moment(risk.RiskBody?.Risk_Comm).format('D-MMM-YYYY'),
                    EndDate: Moment(risk.RiskBody?.Risk_End).format('D-MMM-YYYY')
                }
            }));

            setRiskDates(dates);
            setTotalAmount(amount);
        }
    }, [data])

    const columns = [
        {
            title: 'Risk ID',
            dataIndex: 'RiskId',
            key: 'RiskId',
        },
        {
            title: 'Start Date',
            dataIndex: 'StartDate',
            key: 'StartDate',
        },
        {
            title: 'End Date',
            dataIndex: 'EndDate',
            key: 'EndDate',
        },
        {
            title: 'Actions',
            dataIndex: 'RiskId',
            key: 'Actions',
            render: (id: string) => (
                <>
                    <Tooltip title="Edit">
                        <i className="zmdi zmdi-edit" onClick={() => handleEdit(id)} />
                    </Tooltip>
                    <Tooltip title="Delete">
                        <Popconfirm title={`Are you sure delete Risk: ${id}?`}
                            onConfirm={() => handleDeleteRisk(id)}>
                            {fetching ? <i className="zmdi zmdi-refresh zmdi-hc-spin" /> : <i className="zmdi zmdi-delete" />}
                        </Popconfirm>
                    </Tooltip>
                </>
            )
        }
    ];

    const handleDeleteRisk = async (id: string) => {
        if (data) {
            try {
                await apiRequest(removeRisk(data?.ContID, id));
                await apiRequest(getPolicy(data?.ContID));
                message.success(`Successfully deleted Risk ID: ${id}`);
            } catch (e) {
                message.error('An error has occured');
            }
        }
    }

    const handleEdit = (id: string) => {
        setEditModal(true);
        data?.Risks && setRiskData(data?.Risks[Number(id)]);
    }

    const handleCancelEditModal = () => {
        setEditModal(false);
    }

    const loadInfo = (path, dataIndex, LabelTitle = '') => {
        // console.log(policyData);
        // console.log("this is path", path)
        const data = get(policyData[dataIndex], path);
        let dataToRender;
        // if (path === 'RiskBody.Benefit_Limits') {
        //     return (
        //         <div>Test Data</div>
        //     )
        // }
        debugger;
        if (Object.prototype.toString.call(data) === '[object Object]') {
            dataToRender = Object.keys(data).map((key) => {
                if (typeof data[key] === 'string') {
                    return (
                        <div className="w-100">
                            <label className="col-sm-3"><b>{key}</b></label>
                            <span className='col-sm-6'>{data[key]}</span>
                        </div>
                    )
                } else if (Object.prototype.toString.call(data[key]) === '[object Object]') {
                    console.log("this is path", key, data[key])
                }
            })
        } else if (Object.prototype.toString.call(data) === '[object Array]') {
            const TableHeaderTitles: string[] = []
            const TableRows = data.map((obj, index: number) => {
                if (index === 0) {
                    Object.keys(obj).map(key => TableHeaderTitles.push(key));
                }

                return (
                    <tr key={index}>
                        {
                            TableHeaderTitles.map(key => (
                                <td>{(typeof obj[key] === 'string') ? obj[key] : JSON.stringify(obj[key])}</td>
                            ))
                        }
                    </tr>
                )
            });
            dataToRender = (
                <table className='table'>
                    <thead>
                        <tr>
                            {
                                TableHeaderTitles.map(key => (
                                    <th>{key}</th>
                                ))
                            }
                        </tr>
                    </thead>
                    <tbody>
                        {TableRows}
                    </tbody>
                </table>
            )
        } else if (typeof data === 'string') {
            dataToRender = (
                <div className="w-100">
                    <label className="col-sm-3"><b>{LabelTitle}</b></label>
                    <span className='col-sm-6'>{data}</span>
                </div>
            )
        }
        return dataToRender;
    }

    const renderData = (data, dataIndex, sectionClass = "") => {
        const objKeys = Object.keys(data);
        const result = objKeys.map((key, index) => {
            if (key.includes('GROUP')) {
                return (
                    <div className='d-flex'>
                        {renderData(data[key], dataIndex, 'w-50')}
                    </div>
                );
            } else {
                return (
                    <div className={`${sectionClass} m-ap-form border-box p-20 m-4 ml-unset mr-unset`}>
                        <div className="m-ap-ac-head pl-unset">
                            <div className="m-ap-sub-title">{key}</div>
                        </div>
                        <div className="m-ap-row">
                            {
                                (Object.prototype.toString.call(data[key]) === '[object Object]') && 
                                Object.keys(data[key]).map((label) => loadInfo(data[key][label], dataIndex, label))
                            }
                            {
                                typeof data[key] === 'string' && loadInfo(data[key], dataIndex)
                            }
                        </div>
                    </div>
                )
            }
        });
        return result;
    }

    const renderValue = (json) => {
        let result;
        if (Object.prototype.toString.call(json) === '[object Array]') {
            result = json.map(js => (
                <div className="m-ap-form border-box p-20 m-4">
                    <div className="m-ap-ac-head pl-unset">
                        <div className="m-ap-sub-title">Risk --</div>
                    </div>
                    <div className="m-ap-row">
                        <div className="w-50 m-ap-input-f border-box p-20">
                            <label className="m-ap-label">Ledger ID</label>
                        </div>
                    </div>
                </div>
            ))
        }

        if (Object.prototype.toString.call(json) === '[object Object]') {
            const objKeys = Object.keys(json);
            if (objKeys[0].includes('{n}')) {
                result = objKeys.map((key, index) => {
                    return Object.keys(policyData).map(dataKey => (
                        <div className="m-ap-form border-box p-20 m-4">
                            <div className="m-ap-ac-head pl-unset">
                                <div className="m-ap-sub-title">
                                    {key.replace('{n}', `${dataKey}`)}
                                </div>
                            </div>
                            {renderData(json[key], dataKey)}
                        </div>
                    ))
                })
            } else {
                result = objKeys.map((key, index) => (
                    <div className="m-ap-form border-box p-20 m-4">
                        <div className="m-ap-ac-head pl-unset">
                            <div className="m-ap-sub-title">
                                {key.replace('{n}', `${index + 1}`)}
                            </div>
                        </div>
                        {renderData(json[key], '1')}
                    </div>
                ))
            }

        }
        return result
    }

    const loadContent = () => {
        const test = Object.keys(mappingData) || [];
        const result = test.map(obj => (
            <>
                <div className="m-ap-ac-head">
                    <div className="m-ap-sub-title">{obj}</div>
                </div>

                <div className="m-ap-ac-content">
                    {renderValue(mappingData[obj])}
                </div>
            </>
        )
        );
        return result;
    }

    return (
        <>
            <div id="create-ledger-container">
                <div className="">
                    <div className={`m-ap-accordion m-ap-m-b-15 m-ap-active`}>
                        {loadContent()}
                    </div>
                </div>
            </div>
            {/* <TableContainer columns={columns} rowKey="RiskId" dataSource={tableData} />
            <RiskModal
                risk={riskData}
                isVisible={editModal}
                onOK={handleCancelEditModal}
                onCancel={handleCancelEditModal}
            />
            <Chart riskDates={riskDates} totalAmount={totalAmount}/> */}
        </>
    )
}

export default Coverage;