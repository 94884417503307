import { AGENT_URL, CLIENT_URL, ENV, FINANCE, NEWBUSINESS, PFDomain, KEYCLOAK,POLICYENQUIRY } from './env';

console.log("ENV:"+ENV)
console.log("FINANCE:"+FINANCE)

const CONFIG = {
    local: {
        getPFData: `${PFDomain}/api/v1/product/ThaiProtect/engines/Execute/Finance`,
        getReceipt: `${FINANCE}/finance/v1/receipt/`,
        createReceipt: `${FINANCE}/finance/v1/receipt`,
        applyPremium: `${FINANCE}/finance/v1/applypremium`,
        getVoucher: `${FINANCE}/finance/v1/paymentvoucher/`,
        createVoucher: `${FINANCE}/finance/v1/paymentvoucher`,
        getAccount: `${FINANCE}/v1/account`,
        getReportFromPF: `${PFDomain}/api/v1/product/ThaiProtect/engines/Execute/FWDGIConfig`,
        createMJ: `${FINANCE}/v1/savejournal`,
        getMJ: `${FINANCE}/v1/journal`,
        getOneLedger: `${FINANCE}/v1/ledger/`,
        getLedger: `${FINANCE}/v1/ledgers`,
        createLedger: `${FINANCE}/v1/ledger`,
        AccountingPeriod: `${FINANCE}/v1/accountingperiod`,

    },
    dev: {
        getPFData: `${PFDomain}/api/v1/product/ThaiProtect/engines/Execute/Finance`,
        getReceipt: `${FINANCE}/finance/v1/receipt/`,
        createReceipt: `${FINANCE}/finance/v1/receipt`,
        applyPremium: `${FINANCE}/finance/v1/applypremium`,
        getVoucher: `${FINANCE}/finance/v1/paymentvoucher/`,
        createVoucher: `${FINANCE}/finance/v1/paymentvoucher`,
        getAccount: `${FINANCE}/v1/account`,
        getReportFromPF: `${PFDomain}/api/v1/product/ThaiProtect/engines/Execute/FWDGIConfig`,
        createMJ: `${FINANCE}/v1/savejournal`,
        getMJ: `${FINANCE}/v1/journal`,
        getOneLedger: `${FINANCE}/v1/ledger/`,
        getLedger: `${FINANCE}/v1/ledgers`,
        createLedger: `${FINANCE}/v1/ledger`,

        AccountingPeriod: `${FINANCE}/v1/accountingperiod`,
        getDocuments:`${FINANCE}/v1/document`,
        createDocument:`${FINANCE}/v1/transaction`,

        // Motor Policy
        keycloakURL:`${KEYCLOAK}`,
        getAgent:`${AGENT_URL}v1/search/`,
        getClient:`${CLIENT_URL}v1/client/search/`,
        motorExcelEngine:`${PFDomain}NH_Test/engines/Execute/Motor`,
        createPolicy:`${NEWBUSINESS}v1/newpolicy`,
        policyEnquiry:`${POLICYENQUIRY}`
    },
    devV3: {
        getPFData: `${PFDomain}/api/v1/product/ThaiProtect/engines/Execute/Finance`,
        getReceipt: `${FINANCE}/finance/v1/receipt/`,
        createReceipt: `${FINANCE}/finance/v1/receipt`,
        applyPremium: `${FINANCE}/finance/v1/applypremium`,
        getVoucher: `${FINANCE}/finance/v1/paymentvoucher/`,
        createVoucher: `${FINANCE}/finance/v1/paymentvoucher`,
        getAccount: `${FINANCE}/v1/account`,
        getReportFromPF: `${PFDomain}/api/v1/product/ThaiProtect/engines/Execute/FWDGIConfig`,
        createMJ: `${FINANCE}/v1/savejournal`,
        getMJ: `${FINANCE}/v1/journal`,
        getOneLedger: `${FINANCE}/v1/ledger/`,
        getLedger: `${FINANCE}/v1/ledgers`,
        createLedger: `${FINANCE}/v1/ledger`,

        AccountingPeriod: `${FINANCE}/v1/accountingperiod`,
        getDocuments:`${FINANCE}/v1/document`,
        createDocument:`${FINANCE}/v1/transaction`,
        // Motor Policy
        keycloakURL:`${KEYCLOAK}`,
        getAgent:`${AGENT_URL}v1/search/`,
        getClient:`${CLIENT_URL}v1/client/search/`,
        motorExcelEngine:`${PFDomain}NH_Test/engines/Execute/Motor`,
        createPolicy:`${NEWBUSINESS}v1/newpolicy`,
        policyEnquiry:`${POLICYENQUIRY}`
    },
    demo: {
        getPFData: `${PFDomain}/api/v1/product/ThaiProtect/engines/Execute/Finance`,
        getReceipt: `${FINANCE}/finance/v1/receipt/`,
        createReceipt: `${FINANCE}/finance/v1/receipt`,
        applyPremium: `${FINANCE}/finance/v1/applypremium`,
        getVoucher: `${FINANCE}/finance/v1/paymentvoucher/`,
        createVoucher: `${FINANCE}/finance/v1/paymentvoucher`,
        getAccount: `${FINANCE}/v1/account`,
        getReportFromPF: `${PFDomain}/api/v1/product/ThaiProtect/engines/Execute/FWDGIConfig`,
        createMJ: `${FINANCE}/v1/savejournal`,
        getMJ: `${FINANCE}/v1/journal`,
        getOneLedger: `${FINANCE}/v1/ledger/`,
        getLedger: `${FINANCE}/v1/ledgers`,
        createLedger: `${FINANCE}/v1/ledger`,

        AccountingPeriod: `${FINANCE}/v1/accountingperiod`,
        getDocuments:`${FINANCE}/v1/document`,
        createDocument:`${FINANCE}/v1/transaction`,

        // Motor Policy
        keycloakURL:`${KEYCLOAK}`,
        getAgent:`${AGENT_URL}v1/search/`,
        getClient:`${CLIENT_URL}v1/client/search/`,
        motorExcelEngine:`${PFDomain}NH_Test/engines/Execute/Motor`,
        createPolicy:`${NEWBUSINESS}v1/newpolicy`,
        policyEnquiry:`${POLICYENQUIRY}`
    },
    qa: {
        getPFData: `${PFDomain}/api/v1/product/ThaiProtect/engines/Execute/Finance`,
        getReceipt: `${FINANCE}/finance/v1/receipt/`,
        createReceipt: `${FINANCE}/finance/v1/receipt`,
        applyPremium: `${FINANCE}/finance/v1/applypremium`,
        getVoucher: `${FINANCE}/finance/v1/paymentvoucher/`,
        createVoucher: `${FINANCE}/finance/v1/paymentvoucher`,
        getAccount: `${FINANCE}/v1/account`,
        getReportFromPF: `${PFDomain}/api/v1/product/ThaiProtect/engines/Execute/FWDGIConfig`,
        createMJ: `${FINANCE}/v1/savejournal`,
        getMJ: `${FINANCE}/v1/journal`,
        getOneLedger: `${FINANCE}/v1/ledger/`,
        getLedger: `${FINANCE}/v1/ledgers`,
        createLedger: `${FINANCE}/v1/ledger`,

        AccountingPeriod: `${FINANCE}/v1/accountingperiod`,
        getDocuments:`${FINANCE}/v1/document`,
        createDocument:`${FINANCE}/v1/transaction`,

        // Motor Policy
        keycloakURL:`${KEYCLOAK}`,
        getAgent:`${AGENT_URL}v1/search/`,
        getClient:`${CLIENT_URL}v1/client/search/`,
        motorExcelEngine:`${PFDomain}Motor/engines/Execute/Motor`,
        createPolicy:`${NEWBUSINESS}v1/newpolicy`,
        policyEnquiry:`${POLICYENQUIRY}`
    },
    poc: {
        getPFData: `${PFDomain}/api/v1/product/ThaiProtect/engines/Execute/Finance`,
        getReceipt: `${FINANCE}/finance/v1/receipt/`,
        createReceipt: `${FINANCE}/finance/v1/receipt`,
        applyPremium: `${FINANCE}/finance/v1/applypremium`,
        getVoucher: `${FINANCE}/finance/v1/paymentvoucher/`,
        createVoucher: `${FINANCE}/finance/v1/paymentvoucher`,
        getAccount: `${FINANCE}/v1/account`,
        getReportFromPF: `${PFDomain}/api/v1/product/ThaiProtect/engines/Execute/FWDGIConfig`,
        createMJ: `${FINANCE}/v1/savejournal`,
        getMJ: `${FINANCE}/v1/journal`,
        getOneLedger: `${FINANCE}/v1/ledger/`,
        getLedger: `${FINANCE}/v1/ledgers`,
        createLedger: `${FINANCE}/v1/ledger`,

        AccountingPeriod: `${FINANCE}/v1/accountingperiod`,
        getDocuments:`${FINANCE}/v1/document`,
        createDocument:`${FINANCE}/v1/transaction`,


    },
    boltechDev: {
        getPFData: `${PFDomain}/api/v1/product/ThaiProtect/engines/Execute/Finance`,
        getReceipt: `${FINANCE}/finance/v1/receipt/`,
        createReceipt: `${FINANCE}/finance/v1/receipt`,
        applyPremium: `${FINANCE}/finance/v1/applypremium`,
        getVoucher: `${FINANCE}/finance/v1/paymentvoucher/`,
        createVoucher: `${FINANCE}/finance/v1/paymentvoucher`,
        getAccount: `${FINANCE}/v1/`,
        getReportFromPF: `${PFDomain}/api/v1/product/ThaiProtect/engines/Execute/FWDGIConfig`,
        createMJ: `${FINANCE}/v1/savejournal`,
        getMJ: `${FINANCE}/v1/journal`,
        getOneLedger: `${FINANCE}/v1/ledger/`,
        getLedger: `${FINANCE}/v1/ledgers`,
        createLedger: `${FINANCE}/v1/ledger`,

        AccountingPeriod: `${FINANCE}/v1/accountingperiod`,
        createDocument:`${FINANCE}/v1/transaction`,

    }
};

const endPoint = CONFIG[ENV]

export default endPoint;