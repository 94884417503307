import { journalProps, searchJournalProps } from './interfaces';
import { BreadcrumbProps } from '../Components/interfaces';

export const AllJournalBreadcrumbItems: BreadcrumbProps[] = [
    { title: "Policy 360", redirectTo: "" },
    { title: "Finance", redirectTo: "" },
    { title: "Manual Journal", redirectTo: "/AllJournals" },
    { title: "All", redirectTo: "/" }
];

export const CreateJournalBreadcrumbItems: BreadcrumbProps[] = [
    { title: "Policy 360", redirectTo: "" },
    { title: "Finance", redirectTo: "" },
    { title: "Manual Journal", redirectTo: "/AllJournals" },
    { title: "Create Manual Journal", redirectTo: "/" }
];

export const formData: searchJournalProps = {
    search_value: "",
    search_by: "",
    from_date: "",
    to_date: "",
    allJournal_filter: ""
};

export const sampleJournal: journalProps = {
    JournalID: "",
    UploadDate: "",
    PostingDate: "",
    NumberOfPostings: "",
    Data: "",
    Status: "",
    Notes: ""
}

export const showDate = (date: string) => {
    if (date) {
        return date.substring(0, 10);
    }
    return date;
}

export const parseDate = (date: Date) => {
    const DateToParse = date || new Date();
    return `${DateToParse.getFullYear()}-${DateToParse.getMonth() + 1}-${DateToParse.getDate()}`
}

export const allJournalTableColumns = [
    {
        title: 'Journal ID',
        width: 100,
        dataIndex: 'JournalID',
        key: 'JournalID',
    },
    {
        title: 'Trans. Date',
        dataIndex: 'UploadDate',
        key: 'UploadDate',
        width: 100,
    },
    {
        title: 'Post Date',
        dataIndex: 'PostingDate',
        key: 'PostingDate',
        width: 100,
    },
    {
        title: 'Debit',
        dataIndex: 'NumberOfPostings',
        key: 'NumberOfPostings',
        width: 100,
    },
    {
        title: 'Credit',
        dataIndex: 'NumberOfPostings',
        key: 'NumberOfPostings',
        width: 100,
    },
    {
        title: 'Status',
        dataIndex: 'Status',
        key: 'Status',
        width: 100
    },
    // {
    //     title: 'Actions',
    //     key: 'actions',
    //     dataIndex: 'Status',
    //     width: 100,
    //     render: text => <a data-id={text} onClick={this.onClick}>{text}</a>
    // },
];
