import React, { useEffect, useState } from 'react';
import { navigate } from 'raviger';
// import { useGlobalState } from 'store';
import ReceiptHeader from '../../Receipt/ReceiptHeader';
import { createMjFormProps } from '../interfaces';
import { createMjsecondform, eventProps, journalProps } from '../interfaces';
import { CreateJournalBreadcrumbItems, sampleJournal } from '../ManualJournalController';
import 'bootstrap/dist/css/bootstrap.min.css';
import './CreateManualJournal.styles.scss';
import Breadcrumbs from '../../Components/Breadcrumbs/Breadcrumbs.componet';
import endPoint from 'config/urls';
import Loader from 'Pages/Components/Loader/Loader.componet';

const CreateManualJournal = () => {

    let formData: createMjFormProps = {
        JournalID: "",
        UploadDate: "",
        PostingDate: "",
        Notes: ""
    };

    let formData1: createMjsecondform = {
        AccountID: "",
        SubAccount: "",
        TranType: "",
        SubType: "",
        Amount: "",
        journal_credit: ""
    };

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isEditFlow, setIsEditFlow] = useState<boolean>(false);
    const [selectedJournal, setSelectedJournal] = useState<journalProps>(sampleJournal);
    const [basicSectionActive, setBasicSectionActive] = useState<boolean>(true);
    const [transSectionActive, setTransSectionActive] = useState<boolean>(false);
    const [showAddNew, setShowAddNew] = useState<boolean>(true);
    const [showSuccessModal, setShowSuccessModal] = useState<boolean>(false);
    const [journalNumber, setJournalNumber] = useState<string>("");

    const [mjFormValue, setMjFormValue] = useState<createMjFormProps>(formData);
    const [mjFormsecondValue, setMjFormsecondValue] = useState<createMjsecondform>(formData1);
    const [mjFormTransList, setMjFormTransList] = useState<createMjsecondform[]>([]);
    const [totalCredit, setTotalCredit] = useState<number>(0);
    const [totalDebit, setTotalDebit] = useState<number>(0);
    const [isCreditDebitEqual, setIsCreditDebitEqual] = useState<boolean>(true);
    const [accountList, setAccountList] = useState<{ name: string, value: string }[]>([]);

    useEffect(() => {
        const urlPath = window.location.pathname.split('/');
        if (urlPath.length > 2) {
            setIsEditFlow(true);
            setShowAddNew(false);
            const journalId = urlPath[2];
            getJournalData(journalId);
        }
        getAccountList();
    }, []);

    /**
     * Get account list to add on transactions
     */
    const getAccountList = () => {
        const startUrl = `${endPoint.getAccount}`;
        setIsLoading(true);
        try {
            fetch(`${startUrl}`, {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                }
            })
                .then((response: any) => {
                    const statusCode = response.status;
                    const data = response.json();
                    return Promise.all([statusCode, data]);
                })
                .then(([statusCode, data]) => {
                    setIsLoading(false);
                    if (statusCode === 200 && data.length > 0) {
                        const Accounts: { name: string, value: string }[] = [];
                        // @ts-ignore
                        data.forEach(act => {
                            Accounts.push({
                                name: `${act.AccountID} - ${act.Description}`,
                                value: act.AccountID
                            });
                        });
                        setAccountList(Accounts);
                    }
                });
        } catch (exception) {
            setIsLoading(false);
            console.log(exception);
        }
    }

    /**
     * 
     * @param journalNumber: string - Journal ID to fetch data while update
     * @returns void;
     */
    const getJournalData = async (journalNumber: string) => {
        if (!journalNumber) {
            throw new Error('Journal number not found');
        }
        setIsLoading(true);
        try { // ${endPoint.getOneLedger}
            await fetch(`${endPoint.getMJ}?search_by=JournalID&value=${journalNumber}`, {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                }
            })
                .then((response: any) => {
                    const statusCode = response.status;
                    const data = response.json();
                    return Promise.all([statusCode, data]);
                })
                .then(([statusCode, data]) => {
                    setIsLoading(false);
                    if (statusCode === 200 && data.length > 0) {
                        const mjDetails = { ...data[0] };
                        mjDetails.UploadDate = mjDetails.UploadDate.split("T")[0];
                        mjDetails.PostingDate = mjDetails.PostingDate.split("T")[0];
                        setSelectedJournal(mjDetails);
                        setMjFormValue(mjDetails);
                        let MJdata = JSON.parse(mjDetails.Data);
                        MJdata = MJdata.map((tran: createMjsecondform) => {
                            if (Number(tran.Amount) < 0) {
                                tran.Amount = `${Number(tran.Amount) * -1}`;
                                tran.isNegative = true;
                                tran.journal_credit = "0";
                            } else {
                                tran.journal_credit = tran.Amount;
                                tran.Amount = ""
                                tran.isNegative = false;
                            }
                            return tran;
                        });
                        setMjFormTransList(MJdata);
                    } else {
                        throw new Error('Journal not found');
                    }
                });
        } catch (exception) {
            setIsLoading(false);
            console.log(exception);
        }
    }

    const addNewReceipt = () => {
        const addNewStatus = !showAddNew;
        setShowAddNew(addNewStatus);
    }

    const closeModal = () => {
        setShowSuccessModal(false);
        gotoAllJournal();
    }

    const gotoAllJournal = () => {
        navigate('/AllJournals');
    }

    const handleUpdate = (e: eventProps) => {
        const { value, name } = e.target;
        const headerFormValue = { ...mjFormValue };
        // @ts-ignore
        headerFormValue[name] = value;
        setMjFormValue(headerFormValue);
    }

    const handleJournalTransactionUpdate = (e: eventProps, index: number = -1) => {
        const { value, name } = e.target;
        let currentCredit = 0;
        let currentDebit = 0;
        if (index === -1) {
            const transFormValue = { ...mjFormsecondValue };
            // @ts-ignore
            transFormValue[name] = value;
            currentCredit = Number(transFormValue.journal_credit);
            currentDebit = Number(transFormValue.Amount);
            setMjFormsecondValue(transFormValue);
            updateTotalCreditDebit(currentDebit, currentCredit);
        } else {
            const newTransList = [...mjFormTransList];
            // @ts-ignore
            newTransList[index][name] = value;
            currentCredit = Number(newTransList[index].journal_credit);
            currentDebit = Number(newTransList[index].Amount);
            setMjFormTransList(newTransList);
        }
    }

    const addTransIntoList = () => {
        const transListArray = [...mjFormTransList];
        transListArray.push(mjFormsecondValue);
        setMjFormTransList(transListArray);
        setMjFormsecondValue(formData1);
        setShowAddNew(false);

    }

    const enableEditThis = (index: number) => {
        const newTransList = [...mjFormTransList];
        newTransList[index].editThis = true;
        setMjFormTransList(newTransList);
    }

    const saveUpdatedValues = (index: number) => {
        const newTransList = [...mjFormTransList];
        newTransList[index].editThis = false;
        setMjFormTransList(newTransList);
    }

    useEffect(() => {
        updateTotalCreditDebit();
    }, [mjFormTransList])

    const updateTotalCreditDebit = (da: number = 0, ca: number = 0) => {
        const transArray = mjFormTransList;
        let credit: number = ca;
        let debit: number = da;
        transArray.forEach(trans => {
            credit += Number(trans.journal_credit);
            debit += Number(trans.Amount);
        });
        setTotalCredit(credit);
        setTotalDebit(debit);
        setIsCreditDebitEqual(credit === debit);
    }


    const SubmitManualJournal = () => {
        if (!isCreditDebitEqual) {
            alert('The Credit and Debit amount should be equal');
            return;
        }
        const transDate = mjFormValue.UploadDate + "T00:00:00Z";
        // const postDate = mjFormValue.PostingDate + "T00:00:00Z";
        const TransList = [];
        for (let i = 0; i < mjFormTransList.length; i++) {
            const TransAmount = -1 * Number(mjFormTransList[i].Amount) || Number(mjFormTransList[i].journal_credit);
            const transObj = {
                "AccountID": mjFormTransList[i].AccountID,
                "SubAccount": mjFormTransList[i].SubAccount,
                "PostingDate": transDate,
                "TranType": mjFormTransList[i].TranType,
                "SubType": mjFormTransList[i].SubType,
                "Amount": TransAmount,
                "Notes": "",
                "Data": "{\"SubType\":\"SubType1\"}",
                "Status": "Pending",
                "GLPostStat": "Posted"
            };
            TransList.push(transObj);
        }

        const manualJournal = {
            "JournalID": (isEditFlow) ? selectedJournal.JournalID : "",
            "Date": transDate,
            "PostingDate": transDate,
            "User": "Manual",
            "Notes": mjFormValue.Notes,
            "Status": "Pending",
            "Data": TransList // Transaction List Array
        }
        const url = `${endPoint.createMJ}`;
        console.log("This is payload", manualJournal);
        fetch(url, {
            method: "POST",
            body: JSON.stringify(manualJournal),
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            }
        })
            .then((response) => {
                const statusCode = response.status;
                const data = response.json();
                return Promise.all([statusCode, data]);
            })
            .then(([statusCode, data]) => {
                console.log(data);
                if (statusCode === 200) {
                    setJournalNumber(data.JournalID);
                    setShowSuccessModal(true);
                } else {
                    alert(`Error ${statusCode} - ${data.error}`)
                }
            })
            .catch((error) => {
                console.log(error);
            });
    }

    const copyJournalNumber = () => {
        const text = journalNumber;
        const textField = document.createElement('textarea');
        textField.innerText = text;
        document.body.appendChild(textField);
        textField.select();
        document.execCommand('copy');
        textField.remove();
    }

    return (
        <div id="create-manual-voucher-container">
            <ReceiptHeader />
            {
                isLoading && (
                    <Loader />
                )
            }
            <div className="m-ap-wrap">
                <div
                    className="m-ap-top m-ap-flex m-ap-align-items-center m-ap-justify-content-between m-ap-m-b-15">
                    <Breadcrumbs paths={CreateJournalBreadcrumbItems} />
                </div>

                <div className="m-ap-title">{isEditFlow ? 'Modify' : 'Create New'} Journal</div>

                <div className={`m-ap-accordion m-ap-m-b-15 ${basicSectionActive ? 'm-ap-active' : ''}`}>
                    <div className="m-ap-ac-head" onClick={() => setBasicSectionActive(!basicSectionActive)}>
                        <div className="m-ap-sub-title">Basic Details</div>
                        <div className="m-ap-arrow"></div>
                    </div>
                    {
                        basicSectionActive && (
                            <div className="m-ap-ac-content">
                                <div className="m-ap-form">
                                    <div className="m-ap-row">
                                        <div className="m-ap-col-3 m-ap-input-f">
                                            <label className="m-ap-label">Journal ID</label>
                                            <input type="text" placeholder="Enter Account ID" id="JournalID" name="JournalID"
                                                onChange={(e) => handleUpdate(e)} value={mjFormValue.JournalID} disabled />
                                        </div>
                                        <div className="m-ap-col-3 m-ap-input-f">
                                            <label className="m-ap-label">Transaction Date</label>
                                            <input type="date" placeholder="YYYY/MM/DD" id="UploadDate" name="UploadDate"
                                                onChange={(e) => handleUpdate(e)} value={mjFormValue.UploadDate} />
                                        </div>

                                        <div className="m-ap-col-3 m-ap-input-f">
                                            <label className="m-ap-label">Post Date</label>
                                            <input type="date" placeholder="YYYY/MM/DD" id="PostingDate" name="PostingDate"
                                                onChange={(e) => handleUpdate(e)} value={mjFormValue.PostingDate} />
                                        </div>
                                        <div className="m-ap-col-12 m-ap-input-f">
                                            <label className="m-ap-label">Notes</label>
                                            <textarea placeholder="Enter Note Here..." id="Notes" name="Notes"
                                                onChange={(e) => handleUpdate(e)} value={mjFormValue.Notes} >
                                            </textarea>
                                        </div>
                                    </div>
                                </div>
                                <div className="m-ap-action-btn m-ap-border-top m-ap-flex m-ap-justify-content-end">
                                    <div className="m-ap-btn m-ap-small-btn m-ap-blue"
                                        onClick={() => { setBasicSectionActive(false); setTransSectionActive(true) }}>
                                        Next
                                    </div>
                                </div>
                            </div>
                        )}

                </div>

                <div className={`m-ap-accordion m-ap-m-b-15 ${transSectionActive ? 'm-ap-active' : ''}`}>
                    <div className="m-ap-ac-head" onClick={() => setTransSectionActive(!transSectionActive)}>
                        <div className="m-ap-sub-title">Transaction Details</div>
                        <div className="m-ap-arrow"></div>
                    </div>
                    {
                        transSectionActive && (
                            <div className="m-ap-ac-content">
                                <div className="m-ap-row-table">
                                    <div className="m-ap-table">
                                        <table className="m-ap-h-30">
                                            <thead>
                                                <tr>
                                                    <th className="m-ap-account-id">Account ID</th>
                                                    <th className="m-ap-sub-account">Sub Account</th>
                                                    <th className="m-ap-transaction-type">Transaction Type</th>
                                                    <th className="m-ap-sub-type">Sub Type</th>
                                                    <th className="m-ap-notes">Notes</th>
                                                    <th className="m-ap-data">Data</th>
                                                    <th className="m-ap-debit">Debit</th>
                                                    <th className="m-ap-credit">Credit</th>
                                                    <th className="m-ap-action">Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    mjFormTransList.map((transaction, index) => (
                                                        <>
                                                            {
                                                                !transaction.editThis && (
                                                                    <tr key={`trans-list-${index}`}>
                                                                        <td scope="col">
                                                                            <div className="col-md-12 m-ap-input-f">
                                                                                {transaction.AccountID}
                                                                            </div>
                                                                        </td>
                                                                        <td scope="col">
                                                                            <div className="col-md-12 m-ap-input-f">
                                                                                {transaction.SubAccount}
                                                                            </div>
                                                                        </td>
                                                                        <td scope="col" id="journal_transtype" >
                                                                            <div className="col-md-12 m-ap-input-f">
                                                                                {transaction.TranType}
                                                                            </div>
                                                                        </td>
                                                                        <td scope="col">
                                                                            <div className="col-md-12 m-ap-input-f">
                                                                                {transaction.SubType}
                                                                            </div>
                                                                        </td>
                                                                        <td id="Notes">+<u> Add Notes</u></td>
                                                                        <td id="journal_data">+<u> Add Data</u></td>
                                                                        <td>
                                                                            <div className="col-md-12 m-ap-input-f">
                                                                                {
                                                                                    transaction.Amount
                                                                                }
                                                                            </div>
                                                                        </td>
                                                                        <td>
                                                                            <div className="col-md-12 m-ap-input-f">
                                                                                {transaction.journal_credit}
                                                                            </div>
                                                                        </td>
                                                                        <td scope="col">
                                                                            <span onClick={() => enableEditThis(index)}>
                                                                                <i className="zmdi zmdi-edit zmdi-hc-2x edit-icon"></i>
                                                                            </span>
                                                                            &nbsp;&nbsp;&nbsp;&nbsp;
                                                                            <i className="zmdi zmdi-close zmdi-hc-2x"></i>
                                                                        </td>
                                                                    </tr>
                                                                )
                                                            }
                                                            {
                                                                transaction.editThis && (
                                                                    <tr key={`trans-edit-list-${index}`}>
                                                                        <td scope="col">
                                                                            <div className="col-md-12 m-ap-input-f">
                                                                              <select id="AccountID" name="AccountID"
                                                                                    onChange={(e) => handleJournalTransactionUpdate(e, index)} value={transaction.AccountID}>
                                                                                    <option value="">---Select---</option>
                                                                                    {
                                                                                        accountList.map(acc => (
                                                                                            <option key={acc.value} value={acc.value}>{acc.name}</option>

                                                                                        ))
                                                                                    }
                                                                                </select>
                                                                            </div>
                                                                        </td>
                                                                        <td scope="col">
                                                                            <div className="col-md-12 m-ap-input-f">
                                                                                <input type="text" placeholder="Enter Sub Account" id="SubAccount" name="SubAccount"
                                                                                    onChange={(e) => handleJournalTransactionUpdate(e, index)} value={transaction.SubAccount} />
                                                                            </div>
                                                                        </td>
                                                                        <td scope="col" id="journal_transtype" >
                                                                            <div className="col-md-12 m-ap-input-f">
                                                                                <select id="TranType" name="TranType"
                                                                                    onChange={(e) => handleJournalTransactionUpdate(e, index)} value={transaction.TranType}>
                                                                                    <option value="">---Select---</option>
                                                                                    <option value="Receipt">Receipt</option>
                                                                                    <option value="Premium">Premium</option>
                                                                                    <option value="Option text 3">Option text 3</option>
                                                                                </select>
                                                                            </div>
                                                                        </td>
                                                                        <td scope="col">
                                                                            <div className="col-md-12 m-ap-input-f">
                                                                                <select id="SubType" name="SubType"
                                                                                    onChange={(e) => handleJournalTransactionUpdate(e, index)} value={transaction.SubType}>
                                                                                    <option value="">---Select---</option>
                                                                                    <option value="VAT">VAT</option>
                                                                                    <option value="Stamp Duty">Stamp Duty</option>
                                                                                    <option value="NET">NET</option>
                                                                                </select>
                                                                            </div>
                                                                        </td>
                                                                        <td id="Notes"><u>+ Add Notes</u></td>
                                                                        <td id="journal_data"><u>+ Add Data</u></td>
                                                                        <td>
                                                                            <div className="col-md-12 m-ap-input-f">
                                                                                <input type="number" placeholder="0.00" id="Amount" name="Amount"
                                                                                    onChange={(e) => handleJournalTransactionUpdate(e, index)} value={transaction.Amount}
                                                                                    disabled={Number(transaction.journal_credit) > 0} />
                                                                            </div>
                                                                        </td>
                                                                        <td>
                                                                            <div className="col-md-12 m-ap-input-f">
                                                                                <input type="number" placeholder="0.00" id="journal_credit" name="journal_credit"
                                                                                    onChange={(e) => handleJournalTransactionUpdate(e, index)} value={transaction.journal_credit}
                                                                                    disabled={Number(transaction.Amount) > 0} />
                                                                            </div>
                                                                        </td>
                                                                        <td scope="col">
                                                                            <span onClick={() => saveUpdatedValues(index)}>
                                                                                <i className="zmdi zmdi-check zmdi-hc-2x"></i>
                                                                            </span>
                                                                            &nbsp;&nbsp;&nbsp;&nbsp;
                                                                            <i className="zmdi zmdi-close zmdi-hc-2x"></i>
                                                                        </td>
                                                                    </tr>
                                                                )
                                                            }
                                                        </>
                                                    ))
                                                }

                                                {
                                                    showAddNew && (
                                                        <tr>
                                                            <td scope="col">
                                                                <div className="col-md-12 m-ap-input-f">

                                                                    <select id="AccountID" name="AccountID"
                                                                        onChange={(e) => handleJournalTransactionUpdate(e)} value={mjFormsecondValue.AccountID}>
                                                                        <option value="">---Select---</option>
                                                                        {
                                                                            accountList.map(acc => (
                                                                                <option key={acc.value} value={acc.value}>{acc.name}</option>

                                                                            ))
                                                                        }
                                                                    </select>
                                                                </div>
                                                            </td>
                                                            <td scope="col">
                                                                <div className="col-md-12 m-ap-input-f">
                                                                    <input type="text" placeholder="Enter Sub Account" id="SubAccount" name="SubAccount"
                                                                        onChange={(e) => handleJournalTransactionUpdate(e)} value={mjFormsecondValue.SubAccount} />
                                                                </div>
                                                            </td>
                                                            <td scope="col" id="journal_transtype" >
                                                                <div className="col-md-12 m-ap-input-f" >
                                                                    <select id="TranType" name="TranType"
                                                                        onChange={(e) => handleJournalTransactionUpdate(e)} value={mjFormsecondValue.TranType}>
                                                                        <option value="">---Select---</option>
                                                                        <option value="Receipt">Receipt</option>
                                                                        <option value="Premium">Premium</option>
                                                                        <option value="Option text 3">Option text 3</option>
                                                                    </select>
                                                                </div>
                                                            </td>
                                                            <td scope="col">
                                                                <div className="col-md-12 m-ap-input-f">
                                                                    <select id="SubType" name="SubType"
                                                                        onChange={(e) => handleJournalTransactionUpdate(e)} value={mjFormsecondValue.SubType}>
                                                                        <option value="">---Select---</option>
                                                                        <option value="VAT">VAT</option>
                                                                        <option value="Stamp Duty">Stamp Duty</option>
                                                                        <option value="NET">NET</option>
                                                                    </select>
                                                                </div>
                                                            </td>
                                                            <td id="Notes">+<u> Add Notes</u></td>
                                                            <td id="journal_data">+<u> Add Data</u></td>
                                                            <td>
                                                                <div className="col-md-12 m-ap-input-f">
                                                                    <input type="number" placeholder="0.00" id="Amount" name="Amount"
                                                                        onChange={(e) => handleJournalTransactionUpdate(e)} value={mjFormsecondValue.Amount}
                                                                        disabled={Number(mjFormsecondValue.journal_credit) > 0} />
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <div className="col-md-12 m-ap-input-f">
                                                                    <input type="number" placeholder="0.00" id="journal_credit" name="journal_credit"
                                                                        onChange={(e) => handleJournalTransactionUpdate(e)} value={mjFormsecondValue.journal_credit}
                                                                        disabled={Number(mjFormsecondValue.Amount) > 0} />
                                                                </div>
                                                            </td>
                                                            <td scope="col">
                                                                <span>
                                                                    <i className="zmdi zmdi-check zmdi-hc-2x" onClick={addTransIntoList}></i>
                                                                </span>
                                                                &nbsp;&nbsp;&nbsp;&nbsp;
                                                                <i className="zmdi zmdi-close zmdi-hc-2x"></i>
                                                            </td>
                                                        </tr>
                                                    )
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>

                                <div className="m-ap-border-top ">
                                    <div className="receipt-table-base">
                                        <button type="button" id="add-new" onClick={addNewReceipt}>+ Add New</button>
                                        <div className={`amount-box ${!isCreditDebitEqual ? 'amount-error' : ''}`}>
                                            <table className="table table-sm amount-table">
                                                <tr>
                                                    <th id="th1">&nbsp;&nbsp;</th>
                                                    <th>Debit &nbsp;&nbsp;</th>
                                                    <th>Credit &nbsp;&nbsp;</th>
                                                </tr>
                                                <tr id="tr1">
                                                    <td><b>Total&nbsp;&nbsp;</b></td>
                                                    <td id="Amount"><b>$ {totalDebit}&nbsp;&nbsp;</b></td>
                                                    <td id="journal_credit"><b>$ {totalCredit}&nbsp;&nbsp;</b></td>
                                                </tr>
                                                {/* <tr>
                                                    <td colspan={3} className="error-message">Debit & Credit amounts be matched.</td>
                                                </tr> */}
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                </div>
            </div>

            <div className="m-ap-bottom">
                <button id="create-submit" onClick={SubmitManualJournal}>{isEditFlow ? 'Update' : 'Create'} & Submit for Approval</button>
                <button id="save-draft">
                    {
                        isEditFlow ? 'Update Changes' : 'Save as Draft'
                    }
                </button>
                <button id="cancel" onClick={gotoAllJournal}>Cancel</button>
            </div>

            {
                showSuccessModal && (
                    <div className="modal1" id="myModal">
                        <div className="modal-content1">
                            <div className="col-md-12 mb-2 download-receipt-title1">
                                <span>
                                    {
                                        isEditFlow ? 'Changes Updated' : 'Journal Created'
                                    }
                                </span>
                            </div>
                            <div className="col-md-12 mb-2 receipt-success">
                                <span>
                                    Journal created and send for approval successfully!
                                </span>
                                <br />
                                <span className="entity-number">{journalNumber}&nbsp;</span>
                                <span className="receipt-copy" onClick={copyJournalNumber}>Copy</span>
                            </div>
                            <div className="col-md-12 mb-2 download-receipt-container">
                                <button id="download-receipt-close" onClick={closeModal}>Close</button>
                            </div>
                            <br /><br />
                        </div>
                    </div>
                )
            }
        </div>
    )
}

export default CreateManualJournal;