import React, { useState } from 'react';
// import { navigate } from 'raviger';
// import { useGlobalState } from 'store';
import {
    eventProps, statementDataProps
} from '../interfaces';
import { getStatementPayload } from '../ProductsController';
import 'bootstrap/dist/css/bootstrap.min.css';
import './getStatement.styles.scss';


const GetStatement = () => {

    const [statementData, setStatementData] = useState<statementDataProps[]>([]);
    const [policyNumber, setPolicyNumber] = useState<string>("");
    const [totalValuation, setTotalValuation] = useState<number>(0);


    const handleUpdate = (e: eventProps) => {
        const { value } = e.target;
        setPolicyNumber(value);
    }

    const getStatement = () => {
        const statementPayload = getStatementPayload(policyNumber);

        const url = 'https://newbusiness.demo.sonic.dev.coherent.global/v1/ul/statement';

        fetch(url, {
            method: "POST",
            body: JSON.stringify(statementPayload),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiIxMjM0NTY3ODkwIiwibmFtZSI6IkpvaG4gRG9lIiwiZ3JvdXBzIjpbImFkbWluIiwiaGVhbHRoIl0sImlhdCI6MTUxNjIzOTAyMn0.CMEQlrAI3_MbWZJ1RF0qtvoZi6ZOHKduY3zyIXn8N-o'
            }
        })
            .then((response) => {
                const statusCode = response.status;
                const data = response.json();
                return Promise.all([statusCode, data]);
            })
            .then(([statusCode, data]) => {
                console.log(data);
                const result: statementDataProps[] = data;
                if (statusCode === 200) {
                    setStatementData(result);
                    const totalValue = result.reduce(
                        (acc, obj) => acc + obj.Valuation,
                        0);
                    setTotalValuation(totalValue);
                    // const dummyData = [
                    //     {
                    //         "Id": "USD Cash fund",
                    //         "Units": 209.52,
                    //         "Valuation": 1634.22
                    //     },
                    //     {
                    //         "Id": "Platinum Bond Fund",
                    //         "Units": 345.36,
                    //         "Valuation": 2348.45
                    //     },
                    //     {
                    //         "Id": "Global Share",
                    //         "Units": 262.56,
                    //         "Valuation": 3019.44
                    //     },
                    //     {
                    //         "Id": "SPX Index",
                    //         "Units": 184.34,
                    //         "Valuation": 1677.47
                    //     },
                    //     {
                    //         "Id": "Unallocated Cash",
                    //         "Units": 0,
                    //         "Valuation": 0
                    //     }
                    // ];
                    // const totalValue = dummyData.reduce(
                    //     (acc, obj) => acc + obj.Valuation,
                    //     0);
                    // setTotalValuation(totalValue);
                    // dummyData.push(
                    //     {
                    //         "Id": "Total",
                    //         "Units": 0,
                    //         "Valuation": totalValue
                    //     }
                    // );
                    // setStatementData(dummyData);
                } else {
                    alert(`Error ${statusCode} - ${data.error}`)
                }
            })
            .catch((error) => {
                console.log(error);
            });
    }

    return (
        <div id="create-new-account-container">
            <div className="m-ap-wrap">
                <div className="m-ap-title">Unit Link Insurance</div>
                <div className={`m-ap-accordion m-ap-m-b-15 m-ap-active`}>
                    <div className="m-ap-ac-content pt-4">
                        <div className="m-ap-form">
                            <div className="d-flex flex-column m-3">
                                <div className="m-3">
                                    <label>Policy Number</label>
                                    <input type="text" placeholder="eg. ILP-22-000085" className='form-control col-3'
                                        name="PolicyNumber" id="PolicyNumber" value={policyNumber}
                                        onChange={(e) => handleUpdate(e)} />
                                </div>

                                {
                                    statementData.length > 0 && (
                                        <div className="m-3">
                                            <label>Policy Number</label>
                                            <table className="table table-striped">
                                                <thead>
                                                    <tr>
                                                        <th scope="col">Fund</th>
                                                        <th scope="col">Units</th>
                                                        <th scope="col">Value</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        statementData.map((statement, index) => (
                                                            <tr key={statement.Id}>
                                                                <td>{statement.Id}</td>
                                                                <td>{statement.Units}</td>
                                                                <td>{statement.Valuation}</td>
                                                            </tr>
                                                        ))
                                                    }
                                                    <tr>
                                                        <th scope='row'>Total</th>
                                                        <td></td>
                                                        <td>{totalValuation}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    )
                                }

                            </div>
                        </div>

                    </div>

                </div>

            </div>
            <div className="m-ap-bottom">
                <button id="create-submit" onClick={getStatement}>Get Statement</button>
                <button id="cancel">Cancel</button>
            </div>

        </div >
    )
}

export default GetStatement;