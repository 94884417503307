
/**
 * This mapping json need to be uploaded on Spark.
 * And, will be fetched through an API
 */
const MappingJson = {
    "Risk Details": {
        "Risk {n}": {
            "Vehicle Details": "RiskBody.Vehicle",
            "Risk Details": "RiskBody",
            "Driver(s) Details udpate": "RiskBody.NamedDrivers",
            // "Beneficiaries": "RiskBody.Beneficiaries",
            "Benefit List": "RiskBody.Benefit_Limits"
        }
    }
};

/*

"Risk {n}": {
    "Vehicle Details": "RiskBody.Vehicle",
    "Risk Details": "RiskBody",
    "Driver(s) Details": "RiskBody.NamedDrivers",
    // "Beneficiaries": "RiskBody.Beneficiaries",
    "Benefit List": "RiskBody.Benefit_Limits"
}

"Risk {n}": {
    "GROUP 1": {
        "Vehicle Details": "RiskBody.Vehicle",
        "Risk Details": "RiskBody",
    },
    "Driver(s) Details": "RiskBody.NamedDrivers",
    // "Beneficiaries": "RiskBody.Beneficiaries",
    "Benefit List": "RiskBody.Benefit_Limits"
}

"Risk {n}": {
    "GROUP 1": {
        "Vehicle Details": "RiskBody.Vehicle",
        "Risk Details": {
            "Plan Type": "RiskBody.CoverType",
            "Risk Type": "RiskBody.RiskType",
            "Frequency": "RiskBody.Payment_Frequency",
            "Term": "RiskBody.TermUnits",
            "Start Date": "RiskBody.Risk_Comm",
            "End Date": "RiskBody.Risk_End",
            "Plan": "RiskBody.Plan",
            "Risk Status": "RiskBody.Status"
        }
    },
    "Driver(s) Details": "RiskBody.NamedDrivers",
    // "Beneficiaries": "RiskBody.Beneficiaries",
    "Benefit List": "RiskBody.Benefit_Limits"
}

// ========== HSBC PA -======

"Risk {n}": {
    "Insured Details": "RiskBody.InsuredParty",
    "Risk Details": "RiskBody",
    // "Driver(s) Details": "RiskBody.NamedDrivers",
    // "Beneficiaries": "RiskBody.Beneficiaries",
    "Benefit List": "RiskBody.Benefit_Limits"
}

"Risk {n}": {
    "GROUP 1": {
        "Insured Details": "RiskBody.InsuredParty",
        "Risk Details": "RiskBody"
    },
    // "Driver(s) Details": "RiskBody.NamedDrivers",
    // "Beneficiaries": "RiskBody.Beneficiaries",
    "Benefit List": "RiskBody.Benefit_Limits"
}
        
"Risk {n}": {
    "GROUP 1": {
        "Insured Details": "RiskBody.InsuredParty",
        "Risk Details": {
            "Plan Type": "RiskBody.CoverType",
            "Risk Type": "RiskBody.RiskType",
            "Frequency": "RiskBody.Payment_Frequency",
            "Term": "RiskBody.TermUnits",
            "Start Date": "RiskBody.Risk_Comm",
            "End Date": "RiskBody.Risk_End",
            "Plan": "RiskBody.Plan",
            "Risk Status": "RiskBody.Status"
        }
    },
    // "Driver(s) Details": "RiskBody.NamedDrivers",
    // "Beneficiaries": "RiskBody.Beneficiaries",
    "Benefit List": "RiskBody.Benefit_Limits"
}

// ======== pa ======

"Risk {n}": {
    "GROUP 1": {
        "Insured Details": "RiskBody.InsuredParty",
        "Risk Details": {
            "Plan Type": "RiskBody.CoverType",
            "Risk Type": "RiskBody.RiskType",
            "Frequency": "RiskBody.Payment_Frequency",
            "Term": "RiskBody.TermUnits",
            "Start Date": "RiskBody.Risk_Comm",
            "End Date": "RiskBody.Risk_End",
            "Plan": "RiskBody.Plan",
            "Risk Status": "RiskBody.Status"
        }
    },
    "Beneficiaries": {
        "Beneficary Nomination Type": "RiskBody.Beneficiaries"
    },
    "Benefit List": "RiskBody.Benefit_Limits"
}

"Risk {n}": {
    "GROUP 1": {
        "Insured Details": "RiskBody.InsuredParty",
        "Risk Details": {
            "Plan Type": "RiskBody.CoverType",
            "Risk Type": "RiskBody.RiskType",
            "Frequency": "RiskBody.Payment_Frequency",
            "Term": "RiskBody.TermUnits",
            "Start Date": "RiskBody.Risk_Comm",
            "End Date": "RiskBody.Risk_End",
            "Plan": "RiskBody.Plan",
            "Risk Status": "RiskBody.Status"
        }
    },
    "Beneficiaries": {
        "Beneficary Nomination Type": "RiskBody.Beneficiaries[0].PersonalDets.LegalRep.RepresentativeId"
    },
    "Benefit List": "RiskBody.Benefit_Limits"
}

*/

export const getMappingJson = () => {
    return MappingJson;
}